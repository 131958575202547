import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Navz from "./../../components/Navz";
import Loadable from "react-loadable";
import AuthRoute from "./../AuthRoutes";
import TestComponent from "../../components/lottieComponent";
import NotFound from "../../pages/404NotFound";
import AboutUs from "../../pages/public/provider_signup/AboutUs";
import HowItWorks from "../../pages/public/provider_signup/HowItWorks";
import Searchparking from "../../pages/public/provider_signup/Searchparking";
import { Toaster } from "react-hot-toast";

const LoginSocialMediaPage = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "LoginPage"*/ "./../../pages/public/admin/Login_social_media"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const TermsConditions = Loadable({
  loader: () => import("../../pages/public/provider_signup/TermsConditions"),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const LoginPage = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "LoginPage"*/ "./../../pages/public/admin/Login"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const ForgotPasswordPage = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "ForgotPasswordPage"*/ "./../../pages/public/admin/ForgotPassword"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const ForgotPassword2Page = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "ForgotPassword2Page"*/ "./../../pages/public/admin/ForgotPassword2"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const OtpVerficationPage = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "OtpVerficationPage"*/ "./../../pages/public/admin/OTPVerification"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const ResetPasswordPage = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "ResetPasswordPage"*/ "./../../pages/public/admin/ResetPassword"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const GetStartedPage = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "GetStartedPage"*/ "./../../pages/public/provider_signup/ProviderGetStarted"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const GetStartedPage2 = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "GetStartedPage2"*/ "./../../pages/public/provider_signup/ProviderGetStarted2"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const ContactUs = Loadable({
  loader: () => import("./../../pages/public/provider_signup/ContactUs"),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const BusinessInfoPage = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "BusinessInfoPage"*/ "./../../pages/public/provider_signup/BusinessInformation"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const BusinessInfoPage2 = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "BusinessInfoPage2"*/ "./../../pages/public/provider_signup/BusinessInformation2"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const ReviewInfoPage = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "ReviewInfoPage"*/ "./../../pages/public/provider_signup/ReviewInformation"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const CompleteProfilePage = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "CompleteProfilePage"*/ "./../../pages/public/provider_signup/CompleteProfile"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});
const CompleteBusinessProfile = Loadable({
  loader: () =>
    import("../../pages/public/provider_signup/CompleteBusinessProfile"),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});
const RentYourSpacePage = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "RentYourSpacePage"*/ "./../../pages/public/provider_signup/RentYourSpace"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});
const DashboardContext = Loadable({
  loader: () =>
    import(
      /*webpackChunkName: "DashboardContext"*/ "./../../pages/auth/DashboardContext"
    ),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});

const SearchParkingSpot = Loadable({
  loader: () => import("../../pages/public/provider_signup/Searchparking"),
  loading: () => (
    <div className="d-flex justify-content-center spinner">
      <div className="spinner-border text-muted mt-4 mb-4"></div>
    </div>
  ),
});
function PublicRoute() {
  const isLogedIn =
    localStorage.getItem("provider__token") ||
    sessionStorage.getItem("provider__token");
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 5000,
          style: {
            border: "0.5px solid rgba(192, 192, 192, 0.4)",
            fontWeight: 800,
            fontSize: "14px",
            background: "rgba(0, 0, 0, 0.8)",
            color: "white",
          },
        }}
      />
      {isLogedIn ? (
        <DashboardContext />
      ) : (
        <Router>
          <Navz />
          <Switch>
            <Route exact path="/" component={HowItWorks} />
            <Route exact path="/how-it-works" component={HowItWorks} />
            <Route exact path="/login-link" component={LoginSocialMediaPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route
              exact
              path="/forgotpassword"
              component={ForgotPasswordPage}
            />
            <Route
              path="/forgotpassword/email"
              component={ForgotPassword2Page}
            />
            <Route
              path="/forgotpassword/phone"
              component={ForgotPassword2Page}
            />
            <Route
              path="/forgotpassword/otpverification"
              component={OtpVerficationPage}
            />
            <Route
              path="/forgotpassword/resetpassword"
              component={ResetPasswordPage}
            />
            <Route path="/search-parking" component={SearchParkingSpot} />

            <Route
              exact
              path="/rent-your-space"
              component={RentYourSpacePage}
            />
            <Route
              path="/rent-your-space/get-started-individual"
              component={GetStartedPage}
            />
            <Route
              path="/rent-your-space/get-started-business"
              component={GetStartedPage2}
            />

            <Route path="/notfound" component={NotFound} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/terms-conditions" component={TermsConditions} />
            <Route path="/test" component={TestComponent} />
            <Route path="*" component={() => <Redirect to="/" />} />
          </Switch>
        </Router>
      )}
    </>
  );
}

export default PublicRoute;
