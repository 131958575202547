import React, { useEffect, useState } from "react";
import css from "./Nav.module.css";
import mainCss from "./../main.module.css";
import { Link, useRouteMatch, NavLink } from "react-router-dom";

import Logo from "./../assets/parking-bud-provider-assets/rent-your-parking/add-parking.png";
import { Collapse, Navbar, NavbarToggler, Nav } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

function Navz() {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const AboutUs = useRouteMatch("/about-us");
  const ContactUs = useRouteMatch("/contact-us");
  const testPage = useRouteMatch("/#");
  const rentYourSpacePage = useRouteMatch("/rent-your-space");
  const dashboardPage = useRouteMatch("/dashboard/rent-your-space");
  const howItWorks = useRouteMatch("/how-it-works");
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      {!testPage && (
        <div
          className={_([
            "sticky-top d-flex flex-row justify-content-center align-items-center  p-0 m-0",
          ])}
        >
          <Navbar
            color="light"
            light
            expand="lg"
            className={_([
              "d-flex flex-row navbar navbar-light bg-light shadow ",
              css.nav,
            ])}
          >
            <Link
              className={_([
                "d-flex flex-row justify-content-center align-items-center",
                css.logo_container,
              ])}
              style={{ textDecoration: "none" }}
              to="/"
            >
              <img src={Logo} className={_(["", css.logo_img])}></img>
              <div className={_(["ml-3", css.logo])}>Parking Bud</div>
            </Link>
            <NavbarToggler className={_(["ml-auto"])} onClick={toggle} />
            <Collapse className={_(["m-auto"])} isOpen={isOpen} navbar>
              <Nav
                className={_([
                  "w-100",
                  css.center_box,
                  mainCss.font4,
                  css.flx,
                  mainCss.font_18,
                ])}
              >
                <NavLink
                  className={
                    howItWorks
                      ? _([css.link_text, css.link_text_focused])
                      : _([css.link_text])
                  }
                  onClick={toggle}
                  to="/how-it-works"
                >
                  How It Works
                </NavLink>

                <NavLink
                  className={
                    testPage
                      ? _([css.link_text, css.link_text_focused])
                      : _([css.link_text])
                  }
                  onClick={toggle}
                  to="/#"
                >
                  Book Parking
                </NavLink>

                <NavLink
                  className={
                    rentYourSpacePage || dashboardPage
                      ? _([css.link_text, css.link_text_focused])
                      : _([css.link_text])
                  }
                  onClick={toggle}
                  to="/rent-your-space"
                >
                  Rent Your Parking
                </NavLink>

                <NavLink
                  className={
                    AboutUs
                      ? _([css.link_text, css.link_text_focused])
                      : _([css.link_text])
                  }
                  onClick={toggle}
                  to="/about-us"
                >
                  About Us
                </NavLink>

                <NavLink
                  className={
                    ContactUs
                      ? _([css.link_text, css.link_text_focused])
                      : _([css.link_text])
                  }
                  onClick={toggle}
                  to="/contact-us"
                >
                  Contact Us
                </NavLink>
                {localStorage.getItem("provider__token") ||
                sessionStorage.getItem("provider__token") ? (
                  <NavLink
                    className={
                      ContactUs
                        ? _([
                            css.link_text,
                            css.link_text_focused,
                            "d-block d-lg-none",
                          ])
                        : _([css.link_text, "d-block d-lg-none"])
                    }
                    to={{ pathname: "/login-link", state: "true" }}
                    onClick={() => {
                      localStorage.removeItem("provider__token") ||
                        sessionStorage.removeItem("provider__token");
                      window.location.reload();
                      toggle();
                    }}
                  >
                    Logout
                  </NavLink>
                ) : (
                  <>
                    <NavLink
                      className={
                        ContactUs
                          ? _([
                              css.link_text,
                              css.link_text_focused,
                              "d-block d-lg-none w-100",
                            ])
                          : _([css.link_text, "d-block d-lg-none"])
                      }
                      to={{ pathname: "/login-link", state: "true" }}
                      onClick={toggle}
                    >
                      Login As Provider
                    </NavLink>
                    <a
                      href={process.env.REACT_APP_USER_URL}
                      className={
                        ContactUs
                          ? _([
                              css.link_text,
                              css.link_text_focused,
                              "d-block d-lg-none w-100",
                            ])
                          : _([css.link_text, "d-block d-lg-none"])
                      }
                      onClick={toggle}
                    >
                      Login As User
                    </a>
                  </>
                )}
              </Nav>

              <div className={_(["ml-5 p-4", css.last_sec, css.display_button])}>
                <div className="dropdown">
                  {localStorage.getItem("provider__token") ||
                  sessionStorage.getItem("provider__token") ? (
                    <button
                      //  id="navbarDropdown-toggle"
                      role="button"
                      //  data-toggle="dropdown"
                      //  aria-haspopup="true"
                      //  aria-expanded="false"
                      className={_([
                        "m-2",
                        css.login_button_shadow,
                        mainCss.inside_button1,
                      ])}
                      onClick={() => {
                        localStorage.removeItem("provider__token") ||
                          sessionStorage.removeItem("provider__token");
                        window.location.reload();
                      }}
                    >
                      Logout
                    </button>
                  ) : (
                    <button
                      id="navbarDropdown-toggle"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className={_([
                        "m-2",
                        css.login_button_shadow,
                        mainCss.inside_button1,
                      ])}
                    >
                      Login
                    </button>
                  )}
                  <div
                    className={_(["dropdown-menu p-1"])}
                    aria-labelledby="navbarDropdown"
                  >
                    <button
                      onClick={() => {
                        history.push("/login-link");
                      }}
                      className={_([
                        "w-100 mb-1",
                        css.login_button_shadow,
                        mainCss.inside_button1,
                      ])}
                    >
                      As Provider
                    </button>
                    <br />
                    <button
                      onClick={() => {
                        window.location.replace(process.env.REACT_APP_USER_URL);
                      }}
                      className={_([
                        "w-100",
                        css.login_button_shadow,
                        mainCss.inside_button1,
                      ])}
                    >
                      As User
                    </button>
                  </div>
                </div>
              </div>
            </Collapse>
          </Navbar>
        </div>
      )}
    </>
  );
}
export default Navz;
