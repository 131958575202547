import React, { memo, useCallback, useEffect, useState,useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// icons
import Dot from "../../../../assets/Icons/dot2x.png";
import WheelIcons from "../../../../assets/Icons/wheel.png";
import BusIcon from "../../../../assets/Icons/bus-icon.png";
import HomeIcon from "../../../../assets/Icons/home-parking.png";
import ShildIcon from "../../../../assets/Icons/shield.png";
import CarCleanIcon from "../../../../assets/Icons/car-clean.png";
import PetrolIcon from "../../../../assets/Icons/petrol.png";
import WaterIcon from "../../../../assets/Icons/water.png";
import useAutoHide from "../../../../hooks/useAutoHide";
import axiosInstance from "../../../../axios";

const ListOfParkingSpot = ({ className, setStep, parkings, setSpotIndex, handleBookmark, saved, selected, startTime, endTime }) => {
	const [activeList, setActiveList] = useState(1);
	const history = useHistory();
	const[userid,setuserid]=useState(0)

	const [state, setState] = useState([])
	const[like,setlike]=useState([])
	const[bookedmark,setbookmarked]=useState([])
	const[amenities,setamenities]=useState([])

	const handleCardClick = useCallback(
		(e) => {
			setStep("spot-details");
			setSpotIndex(e)
			
		},
		[setStep]
	);
	const likeparkingspot= async(e)=>{
		let formData = new FormData();
		formData.append('parking_id', e.target.value)
		await axiosInstance.post(
			"consumer_dash/like_parking",
			formData
		).then((response) => {
			if(response){
				if(like.includes(e.target.value)){
				like.splice(like.indexOf(e.target.value),1)
					setlike([...like])
				}
				else{
					setlike([...like,e.target.value])
				}
			}
		}).catch((err) => {
			console.log("get an error", err.response)
		})


	}
	const bookmark= async(e)=>{
		let formData = new FormData();
		formData.append('parking_id', e.target.value)
		await axiosInstance.post(
			"consumer_dash/bookmarked",
			formData
		).then((response) => {
			if(response){
				if(bookedmark.includes(e.target.value)){
					bookedmark.splice(bookedmark.indexOf(e.target.value),1)
					setbookmarked([...bookedmark])
					}
					else{
						setbookmarked([...bookedmark,e.target.value])
					}
			}
		}).catch((err) => {
			console.log("----", err.response)
		})


	}
	useEffect(async ()=>{
		
		await axiosInstance.post(
			"consumer_dash/get_active_amenities_detail"
		).then((response) => {
			if(response){
				setamenities(response.data.response.data)
			}
		}).catch((err) => {
			console.log("----", err.response)
		})


	},[])
	useEffect(async ()=>{
		
		await axiosInstance.get(
			"consumer_dash/edit_consumer_profile"
		).then((response) => {
			if(response){
				
				setuserid(response.data.response.user.id)
			}
		}).catch((err) => {
			console.log("----", err.response)
		})


	},[])

	const hourtype=useRef()
	const amentie=useRef()

	const[open ,setopen]=useAutoHide(
		hourtype,
		amentie,
	)
	const [monthly,setmonthly]=useState(false)
	const[hourly,sethourly]=useState(false)
	const[amenitiesfilter,setamenitiesfilter]=useState([])
	const amentiedata=(val)=>{
		setamenitiesfilter([...amenitiesfilter,val])
	}
	
	
		const[selectbtn,setseletedbtn]=useState('H')
		const btnn=(val)=>{
			
			setseletedbtn(val)
			if (val==='H'){
				sethourly(true)
				setmonthly(false)
			}
			else{
				setmonthly(true)
				sethourly(false)
			}
		};
	const handleBooking = useCallback((parking, startTime, endTime) => {
		window.location.replace('https://stg-uf.parkingbud.com/home/reservation/'+parking.parking_name+'/'+parking.id)
		// history.push({pathname:'https://stg-uf.parkingbud.com/home/reservation/'+parking.parking_name+'/'+parking.id, state: {parking: parking, startTime: startTime, endTime:endTime}});
		// history.push({pathname:'/home/reservation/'+parking.parking_name+'/'+parking.id, state: {parking: parking, startTime: startTime, endTime:endTime}});
	}, [history]);

	useEffect(()=>{
		if(activeList === 1){
			setState(parkings)
		}else{
			setState(saved)
		}
		
	}, [activeList, parkings, saved])


	return (
		<div className={className}>
			<div className='wrapper'>
				<button className='filter' onClick={()=>setopen((prevState)=>!prevState)}>
					<span>Filter</span>
					<i className='bi bi-sliders'></i>
				</button>
				<div ref={hourtype} className={open?'show':'filterpopup'}>
					<div className='d-flex justify-content-center'>
					<button className='filter' onClick={()=>setopen((prevState)=>!prevState)}>
						<span>Filter</span>
						<i className='bi bi-sliders'></i>
					</button>
					</div>
					<div className='typeofoperation row m-0 w-100 '>
						<div className="col-md-6 p-1">
						<button className={selectbtn==='H'?"button1":"button2"}onClick={()=>btnn("H")}>Hourly</button>
						</div>
						<div className="col-md-6 p-1">
						<button className={selectbtn==='M'?"button1":"button2"} onClick={()=>btnn("M")}>Monthly</button>
						</div>
					</div>
					<div className='Amenties'>
						{amenities.map((amnt)=><><label><input type="checkbox" onClick={()=>amentiedata(amnt.name)}/> <img  src={amnt.icon_img} style={{width:'1rem',height:'1rem'}} alt=''/> <span style={{color:'grey',fontSize:'.9rem'}}>{amnt.name}</span></label><br/></>)}
						
					</div>
				</div>
				
				<section className='menu'>
					<h3
						className={activeList === 1 ? "active" : ""}
						onClick={() => setActiveList(1)}>
						All
					</h3>
					<h3
						className={activeList === 2 ? "active" : ""}
						onClick={() => setActiveList(2)}>
						Saved
					</h3>
				</section>

				<section className='sort__info'>
					<p>{state.length} Parkings</p>
					<div className='sort'>
						<p>Sort by:</p>
						<select name='' id=''>
							<option value=''>None</option>
							<option value=''>distance</option>
						</select>
					</div>
				</section>

				<section className='lists'>
					{state
						.map((value, i) => (
							<div className={'infoCard' + (selected === i? " infoCard2": "")} key={i} >
								<div className='info'>
									<div className='img__wrapper'>
										<img
											src={value.pic_1? 
													value.pic_1: 
													value.pic_2? 
													value.pic_2: 
													value.pic_3? 
													value.pic_3: 
													value.pic_4? 
													value.pic_4:
													'https://images.pexels.com/photos/2008269/pexels-photo-2008269.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'}
											alt='parking'
											 />

									</div>
									<div className='details' onClick={()=>handleCardClick(i)}>
										<h6>{value.parking_name}</h6>
										<p>
										{value.parking_spot_address1 != ""?value.parking_spot_address1+", ": null}
										{value.parking_spot_address2 != ""?value.parking_spot_address2+", ": null}
										{value.city != ""?value.city+" ": null}
										</p>

										<div className='start__info'>
											<p>
												<i className='bi bi-star-fill'></i>
												{value.total_ratings}
											</p>
											<img src={Dot} alt='dot' className='divider' />
											<p>
												<i className='bi bi-suit-heart-fill'></i>
												{value.likes}
											</p>
											<img src={Dot} alt='dot' className='divider' />
											<p>
												<i className='bi bi-geo-alt-fill'></i>
												{value.distance}
											</p>
										</div>
									</div>

										</div>
								<div className='amenities'>
									{value.amenities &&
										value.amenities.map((item, i) => {
											return (
												<>
												{item.icon_img && <img src={item.icon_img} alt='WheelIcons' />}
												</>
											)
										})
									}

									{/*<img src={WheelIcons} alt='WheelIcons' />
									<img src={BusIcon} alt='BusIcon' />
									<img src={HomeIcon} alt='HomeIcon' />
									<img src={ShildIcon} alt='ShildIcon' />
									<img src={CarCleanIcon} alt='CarCleanIcon' />
								<img src={PetrolIcon} alt='PetrolIcon' />*/}
								</div>
								{value.extension_report &&
								
								<div className="input__err__group">
									<span>{"Extension time not available for more than "+value.extension_report}</span>

								</div>
								
								}
								{value.message &&
								
								<div className="input__err__group">
									<span>{value.message}</span>

								</div>
								
								}

								<div className='pay__section'>
									<button disabled>${value.hourly_rate}</button>
									<button className='appButton' onClick={() => handleBooking(value, startTime, endTime)}>
										Book Now
									</button>
								</div>
							</div>
						))}
				</section>
			</div>
		</div>
	);
	
};


ListOfParkingSpot.propTypes = {
	className: PropTypes.string.isRequired,
	setStep: PropTypes.func.isRequired,
};

export default memo(ListOfParkingSpot);
