import React, { memo, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useAutoHide from "../../hooks/useAutoHide";

const TimePicker = ({ className, setTime, time }) => {
	// 12 hr format
	const hours = [
		"01",
		"02",
		"03",
		"04",
		"05",
		"06",
		"07",
		"08",
		"09",
		"10",
		"11",
		"12",
	];

	// every 15 mins
	const mins = ["00", "15", "30", "45"];

	const hourRef = useRef(null);
	const minRef = useRef(null);

	// auto hide
	const [showHour, setShowHour] = useAutoHide(hourRef, "null");
	const [showMin, setShowMin] = useAutoHide(minRef, "null");

	const handleChangeHour = useCallback(
		(value) => {
			console.log("Value", value);
			setTime((prevState) => ({
				...prevState,
				hour: value,
			}));
		},
		[setTime]
	);

	const handleChangeMin = useCallback(
		(value) => {
			setTime((prevState) => ({
				...prevState,
				min: value,
			}));
		},
		[setTime]
	);

	// when clicks on input
	const handleClickHourInput = useCallback(() => {
		setShowHour((prevState) => !prevState);
	}, [setShowHour]);

	const handleClickMinInput = useCallback(() => {
		setShowMin((prevState) => !prevState);
	}, [setShowMin]);

	return (
		<div className={className}>
			<p id='hour' onClick={handleClickHourInput}>
				{time?.hour}
			</p>
			<div
				ref={hourRef}
				className={showHour ? "dropdown__time hour" : "dropdown__time"}>
				{hours.map((value, i) => (
					<p key={i} onClick={() => handleChangeHour(value)}>
						{value}
					</p>
				))}
			</div>
			:
			<p id='sec' onClick={handleClickMinInput}>
				{" "}
				{time?.min}
			</p>
			<div
				ref={minRef}
				className={showMin ? "dropdown__time min" : "dropdown__time"}>
				{mins.map((value, i) => (
					<p key={i} onClick={() => handleChangeMin(value)}>
						{value}
					</p>
				))}
			</div>
		</div>
	);
};

TimePicker.propTypes = {
	className: PropTypes.string.isRequired,
	time: PropTypes.exact({
		date: PropTypes.object,
		finalDate: PropTypes.object,
		hour: PropTypes.string,
		min: PropTypes.string,
		am: PropTypes.bool,
	}),
	setTime: PropTypes.func,
};

export default memo(TimePicker);
