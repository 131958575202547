export const ERROR__PROCESSOR = (errors, errMsg, setErrMsg) => {
	let temp = errMsg;
    console.log(errors)
    for(var err in errors){
        
        
        if(err in errMsg){
            temp = {...temp, [err]: errors[err][0]}
        }
    }
    setErrMsg(temp)
	
}

export const DATA_PROCESSOR =  async (data, state, setState) => {
    let temp = state;
    for(const key in data){
        if(key in state){
            temp = {...temp, [key]: data[key]}
        }
    }
    await setState(temp)
}

export const CONVERTMINUTESTOHHMM = (minutes) => {
    var MINUTES = minutes; //some integer

    var m = MINUTES % 60;

    var h = (MINUTES-m)/60;

    return h.toString() + "h " + (m<10?"0":"") + m.toString() + "m";
}


export const DATETOWORDS = (date) => {
    var dob = new Date(date);
    var dobArr = dob.toDateString().split(' ');
    return  dobArr[1] + ' ' + dobArr[2] + ', ' + dobArr[3];
}

export const TIMETOWORDS = (time) => {
    const t = time.split(":")
    return t[0] + "h " + t[1] + "m"
}

export const GETTIMEFROMDATE = (date) => {
    var dob = new Date(date);
    return dob.toLocaleString('en-US', { hour: 'numeric', hour12: true })
}

export const DateConvert = (date) => {
    var dob = new Date(date);
    return dob
}


export const  TIMEDIFFERENCECALC = (date1, date2) => {
    let dateFuture = new Date(date2)
    let dateNow = new Date(date1)
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = '';
    if (days > 0) {
      difference += (days === 1) ? `${days} day ` : `${days} days `;
    }
    else{
        difference += (hours === 0 || hours === 1) ? `${hours} h ` : `${hours} h `;

        difference += (minutes === 0 || hours === 1) ? `${minutes} m` : `${minutes} m`; 
    }

    return difference;
}

export const ISINTERSECT = (arr, n) =>
{
    console.log("Array", arr)
    // Sort intervals in increasing order of start time
    
    arr.sort(function(i1, i2){
        console.log(i1, i2, "GOOL")
        return Date.parse('01/01/2011 '+ i1.from) - Date.parse('01/01/2011 '+ i2.from);;
        
    });
    console.log("Sorted Array", arr, n)
    
    // In the sorted array, if start time of an interval
    // is less than end of previous interval, then there
    // is an overlap
    for(let i = 1; i < n; i++){
        console.log("something", Date.parse('01/01/2011 '+ arr[i - 1].to), Date.parse('01/01/2011 '+ arr[i].from))
        if (Date.parse('01/01/2011 '+ arr[i - 1].to) > Date.parse('01/01/2011 '+ arr[i].from)){
            console.log("output", true);
            return null
        }
    }
    let string = ""        
    // If we reach here, then no overlap
    for(let j = 0; j < n; j++){
        string = string.concat(arr[j].from + "-" + arr[j].to + ",")
        console.log(string)
    }
    console.log("real", string)
    console.log("output",false);
    return string
    
}

const tConvert = (time) => {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
}


export const CONVERTHOURSOFOPERATION = (str) => {
    let temp = str.split(",")
    for(var i in temp){
        if(temp[i] != ""){
            //console.log("i--",temp[i])
            let x = temp[i].split("-")
            temp[i] = tConvert(x[0]) + " - " + tConvert(x[1]) + " "
            console.log("i----", temp[i])
        }
    }
    return temp
}