import { SET_OTP_OPTION, SET_EMAIL } from "./action_type";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case SET_OTP_OPTION:
      return (state = action.payload);
    case SET_EMAIL:
      return (state = action.payload);

    default:
      return state;
  }
};