import { useEffect, useCallback, useState } from "react";
import PropsTypes from "prop-types";

const useAutoHide = (ref, id) => {
	const [show, setShow] = useState(false);

	const handleClick = useCallback(
		(e) => {
			// this is to check if ref is a sidebar for example or other div
			if (
				ref.current &&
				!ref.current.contains(e.target) &&
				e.target.id !== id
			) {
				// !ref.current.contains(e.target) ===>  that ensures that the clicked div is toggle div or outsider div
				setShow(false);
			}
		},
		[ref, id]
	);

	useEffect(() => {
		document.addEventListener("click", handleClick, true);
		return () => {
			document.addEventListener("click", handleClick, true);
		};
	}, [ref, handleClick]);

	return [show, setShow];
};

useAutoHide.propTypes = {
	ref: PropsTypes.node,
	id: PropsTypes.string,
};

export default useAutoHide;