import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Navz from "./../../components/Navz";
import AuthRoute from "./../AuthRoutes";
import TestComponent from "../../components/lottieComponent";
import NotFound from "../../pages/404NotFound";
import AboutUs from "../../pages/public/provider_signup/AboutUs";
import HowItWorks from "../../pages/public/provider_signup/HowItWorks";
import Searchparking from "../../pages/public/provider_signup/Searchparking";
import { Toaster } from "react-hot-toast";
import LottieGIF from "../../components/lottieComponent";
import loader from "../../assets/JSON-Gifs/GIF/25.loading.json";
import DefaultLoader from "../../usable/DefaultLoader";

const _ = (classes) => {
  let s = "";
  classes.map((i) => (s += i + " "));
  return s;
};

// Lazy load components
const LoginSocialMediaPage = React.lazy(() => import("./../../pages/public/admin/Login_social_media"));
const TermsConditions = React.lazy(() => import("../../pages/public/provider_signup/TermsConditions"));
const LoginPage = React.lazy(() => import("./../../pages/public/admin/Login"));
const ForgotPasswordPage = React.lazy(() => import("./../../pages/public/admin/ForgotPassword"));
const ForgotPassword2Page = React.lazy(() => import("./../../pages/public/admin/ForgotPassword2"));
const OtpVerficationPage = React.lazy(() => import("./../../pages/public/admin/OTPVerification"));
const ResetPasswordPage = React.lazy(() => import("./../../pages/public/admin/ResetPassword"));
const GetStartedPage = React.lazy(() => import("./../../pages/public/provider_signup/ProviderGetStarted"));
const GetStartedPage2 = React.lazy(() => import("./../../pages/public/provider_signup/ProviderGetStarted2"));
const ContactUs = React.lazy(() => import("./../../pages/public/provider_signup/ContactUs"));
const BusinessInfoPage = React.lazy(() => import("./../../pages/public/provider_signup/BusinessInformation"));
const BusinessInfoPage2 = React.lazy(() => import("./../../pages/public/provider_signup/BusinessInformation2"));
const ReviewInfoPage = React.lazy(() => import("./../../pages/public/provider_signup/ReviewInformation"));
const CompleteProfilePage = React.lazy(() => import("./../../pages/public/provider_signup/CompleteProfile"));
const CompleteBusinessProfile = React.lazy(() => import("../../pages/public/provider_signup/CompleteBusinessProfile"));
const RentYourSpacePage = React.lazy(() => import("./../../pages/public/provider_signup/RentYourSpace"));
const DashboardContext = React.lazy(() => import("./../../pages/auth/DashboardContext"));
const SearchParkingSpot = React.lazy(() => import("../../pages/public/provider_signup/Searchparking"));

function PublicRoute() {
  const isLogedIn =
    localStorage.getItem("provider__token") ||
    sessionStorage.getItem("provider__token");
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 5000,
          style: {
            border: "0.5px solid rgba(192, 192, 192, 0.4)",
            fontWeight: 800,
            fontSize: "14px",
            background: "rgba(0, 0, 0, 0.8)",
            color: "white",
          },
        }}
      />
      {isLogedIn ? (
        <Suspense fallback={<DefaultLoader />}>
          <DashboardContext />
        </Suspense>
      ) : (
        <Router>
          <Navz />
          <Suspense fallback={<DefaultLoader />}>
            <Switch>
              <Route exact path="/" component={HowItWorks} />
              <Route exact path="/how-it-works" component={HowItWorks} />
              <Route exact path="/login-link" component={LoginSocialMediaPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route
                exact
                path="/forgotpassword"
                component={ForgotPasswordPage}
              />
              <Route
                path="/forgotpassword/email"
                component={ForgotPassword2Page}
              />
              <Route
                path="/forgotpassword/phone"
                component={ForgotPassword2Page}
              />
              <Route
                path="/forgotpassword/otpverification"
                component={OtpVerficationPage}
              />
              <Route
                path="/forgotpassword/resetpassword"
                component={ResetPasswordPage}
              />
              <Route path="/search-parking" component={SearchParkingSpot} />

              <Route
                exact
                path="/rent-your-space"
                component={RentYourSpacePage}
              />
              <Route
                path="/rent-your-space/get-started-individual"
                component={GetStartedPage}
              />
              <Route
                path="/rent-your-space/get-started-business"
                component={GetStartedPage2}
              />

              <Route path="/notfound" component={NotFound} />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/terms-conditions" component={TermsConditions} />
              <Route path="/test" component={TestComponent} />
              <Route path="*" component={() => <Redirect to="/" />} />
            </Switch>
          </Suspense>
        </Router>
      )}
    </>
  );
}

export default PublicRoute;
