import React, { memo, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

const Calender = ({ viewCalender, setViewCalender }) => {
	const [calender, setCalender] = useState([]);
	// const [viewCalender, ] = useState(MOMENT(new Date(date)));

	const [weekDays] = useState([
		"Mon",
		"Tue",
		"Wed",
		"Thu",
		"Fri",
		"Sat",
		"Sun",
	]);

	const getDataFromCalender = useCallback(() => {
		let tempStoreDate = [];

		let currentDate = viewCalender?.date;
		// console.log("Hurr", date);
		let startDayOfMonth = currentDate
			.clone()
			.startOf("month")
			.startOf("week")
			.add(1, "day")
			.subtract(1, "week");

		let endDayOfMonth = currentDate
			.clone()
			.endOf("month")
			.endOf("week")
			.add(1, "day");

		let tempDate = startDayOfMonth.subtract(1, "day");

		// adding all dates to array b/w start and end date
		while (tempDate.isBefore(endDayOfMonth, "day")) {
			tempStoreDate.push(
				Array(7)
					.fill(0)
					.map(() => {
						return {
							date: tempDate.add(1, "day").clone(),
						};
					})
			);
		}

		setCalender(tempStoreDate);
		// console.log("SSS", startDayOfMonth);
	}, [viewCalender]);

	useEffect(() => {
		getDataFromCalender();
	}, [getDataFromCalender]);

	const handleDateClick = useCallback(
		(date) => {
			setViewCalender((prevState) => ({
				...prevState,
				date,
			}));
		},
		[setViewCalender]
	);

	return (
		<div className='calender'>
			<div className='weeks'>
				{weekDays.map((value, i) => (
					<p key={i}>{value}</p>
				))}
			</div>

			<div className='days'>
				{calender.map((value, i) => (
					<div className='box__row' key={i}>
						{value.map((day, j) => (
							<div
								className={
									viewCalender.date.isSame(day.date, "day")
										? "box__col active"
										: "box__col"
								}
								key={j}
								onClick={() => handleDateClick(day.date)}>
								<p>
									{viewCalender.date.isSame(day.date, "month")
										? day.date.format("DD")
										: ""}
								</p>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

Calender.propTypes = {
	viewCalender: PropTypes.exact({
		date: PropTypes.object,
		finalDate: PropTypes.object,
		hour: PropTypes.string,
		min: PropTypes.string,
		am: PropTypes.bool,
	}),
	setViewCalender: PropTypes.func,
};

export default memo(Calender);
