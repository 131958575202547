import React from "react";
import { Redirect, Route } from "react-router-dom";

const AuthRoute = (props) => {
	if (localStorage.getItem("provider__token")) {
		return <Route {...props} />;
	} else if (sessionStorage.getItem("provider__token")) {
		return <Route {...props} />;
	}
	return <Redirect to='/' />;
};

export default AuthRoute;