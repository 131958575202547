import axios from "axios";
import dotenv from 'dotenv';
dotenv.config({path: '/server/.env'});

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_DEVELOPMENT_URL,
	headers: {
		accept: "*/*",
		Authorization: ((localStorage.getItem("provider__token"))? localStorage.getItem("provider__token"): sessionStorage.getItem("provider__token")) || '',
	},
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const { response } = error;

		if (response && response.status === 401) {
			localStorage.removeItem("provider__token");
			sessionStorage.removeItem("provider__token");

			window.location.href = "/login";
		}
		return Promise.reject(error);
	}
);


export default axiosInstance;