import React from 'react';

class ParInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className={this.props.className}>
                {
                    this.props.label ?
                        <label className='text-blue' to={this.props.inputID}>{this.props.label}</label>
                        : null
                }
                {
                    this.props.errorMsg ?
                        <span className={"help-block text-danger " + this.props.errorClass}>{this.props.errorMsg}</span>
                        : null
                }
                <input type={this.props.inputType} id={this.props.inputID} placeholder={this.props.placeholder}
                    className={this.props.inputClass} value={this.props.value} name={this.props.name} onChange={this.props.onChange} />
            </div>
        )
    }
}


export default ParInput;