import React, { memo, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { dashedPolyLineMap } from "../../../../config/app.config";

// components
import SearchDestination from "./SearchDestination";

// icons
import TopDownArrowsIcons from "../../../../assets/Icons/up-down-marker.png";
import LocationIcon from "../../../../assets/Icons/location.png";
import Logo from "../../../../assets/logo.png";
import BoldMarkerIcon from "../../../../assets/Icons/markerBold.svg";
import CarIcon from "../../../../assets/car.svg";

const SpotDirection = ({ className, setStep, map, position, parking }) => {
	const [directionServ, setDirectionServ] = useState(null);
	const [directionRend, setDirectionRend] = useState(null);
	const [markerPos, setMarkerPos] = useState([]);

	// for search direction
	const [state, setState] = useState({
		address: "",
		location: {
			lat: 0,
			lng: 0,
		},
	});

	const [stateParking, setStateParking] = useState({
		location: {
			lat: 0,
			lng: 0,
		},
	});

	const handleCardClick = useCallback(
		(e) => {
			setStep("spot-details");
		},
		[setStep]
	);

	const makeMarker = useCallback(
		(position, icon, title) => {
			return new window.google.maps.Marker({
				position: position,
				map: map,
				icon: icon,
				zIndex: 2,
				optimized: true,
				label: title
					? {
							text: title,
							color: "white",
							fontSize: "16px",
							fontFamily: "ProximaNova",
					  }
					: undefined,
			});
		},
		[map]
	);

	const getIcon = useCallback(() => {
		return {
			start: {
				url: CarIcon,
				// This marker is 20 pixels wide by 32 pixels high.
				size: new window.google.maps.Size(100, 100),
				// The origin for this image is (0, 0).
				origin: new window.google.maps.Point(0, 0),
				// The anchor for this image is the base of the flagpole at (0, 32).
				anchor: new window.google.maps.Point(50, 50),
			},

			end: {
				url: BoldMarkerIcon,
				// This marker is 20 pixels wide by 32 pixels high.
				size: new window.google.maps.Size(60, 80),
				// The origin for this image is (0, 0).
				origin: new window.google.maps.Point(0, 0),
				// The anchor for this image is the base of the flagpole at (0, 32).
				anchor: new window.google.maps.Point(30, 65),
			},
		};
	}, []);

	const generateLocation = useCallback((lat, lng) => {
		return new window.google.maps.LatLng(lat, lng);
	}, []);

	const calculateRoute = useCallback(() => {
		if (!map || !directionServ) return;

		if (!position?.lat || !position?.lng) return;

		// reset marker map
		if (markerPos.length > 0)
			markerPos.forEach((value) => {
				value.setMap(null);
			});

		// cleaning direction Render
		if (directionRend) directionRend.setMap(null);

		// converting lat & lng to LatLng object
		const location =
			state.location.lat !== 0 && state.location.lng !== 0
				? generateLocation(state.location.lat, state.location.lng)
				: generateLocation(position?.lat, position?.lng);
		const destination = 
			stateParking.location.lat && stateParking.location.lng
				? generateLocation(stateParking.location.lat, stateParking.location.lng)
				: generateLocation(position?.lat, position?.lng);

		// map.setCenter(location);
		// map.setZoom(20);
		// 22.6763858,88.0495254

		const request = {
			origin: location,
			destination: destination,
			travelMode: "DRIVING",
			unitSystem: window.google.maps.UnitSystem.IMPERIAL,
			provideRouteAlternatives: true,
		};

		directionServ.route(request, (result, status) => {
			if (status === "OK") {
				const directionsRenderer = new window.google.maps.DirectionsRenderer({
					map,
					suppressMarkers: true,
					directions: result,
					polylineOptions: dashedPolyLineMap,
				});

				const leg = result.routes[0].legs[0];

				const myLocationIcon = getIcon().start;
				const endLocationIcon = getIcon().end;

				setMarkerPos([
					makeMarker(leg.start_location, myLocationIcon, "", map),
					//makeMarker(leg.end_location, endLocationIcon, "$13", map),
				]);

				setDirectionRend(directionsRenderer);
			} else {
				alert("No Route Found");
			}
		});
	}, [
		map,
		directionServ,
		position,
		makeMarker,
		getIcon,
		markerPos,
		state,
		generateLocation,
		directionRend,
		stateParking
	]);

	// initiate direction
	useEffect(() => {
		if (!map) return;
		//map.setZoom(13);

		const directionsService = new window.google.maps.DirectionsService();

		// directionsRenderer.setMap(map);

		setDirectionServ(directionsService);

		return () => {
			if (markerPos.length <= 0) return;

			markerPos.forEach((value) => {
				value.setMap(null);
			});
		};
	}, [map, markerPos]);

	useEffect(() => {
		if(!parking || !parking.loc_lat || !parking.loc_lon){
			return
		}
		setStateParking({
			...stateParking,
			location: {
				lat: parking.loc_lat,
				lng: parking.loc_lon
			}
		})
	}, [parking])

	useEffect(() => {
		if(!position){
			return
		}
		setState({
			...stateParking,
			location: {
				lat: position.lat,
				lng: position.lng
			}
		})
	}, [position])

	console.log("----searching", state)


	return (
		<div className={className}>
			<div className='wrapper'>
				<button className='back' onClick={handleCardClick}>
					<span>Back</span>
				</button>

				<div className='destination'>
					<img src={TopDownArrowsIcons} alt='top-down-arrow' />

					<div className='forms__direction'>
						<div className='input__group'>
							<img id='location' src={LocationIcon} alt='top-down-arrow' />

							<SearchDestination
								calculateRoute={calculateRoute}
								setState={setState}
								state={state}
								map={map}
							/>
						</div>

						<div className='input__group'>
							<img id='logo' src={Logo} alt='top-down-arrow' />

							<div className='input__wrapper'>
								<input type='text' placeholder={parking? parking.parking_name: null} disabled />
							</div>
						</div>
					</div>
				</div>

				<div className='price'>
					<button type='button' disabled>
						$5/Hour
					</button>
				</div>

				<div className='contact__details'>
					<div className='info__contact'>
						<div className='location'>
							<i className='bi bi-geo-alt-fill'></i>
							<p>
							{parking &&
								<>
								<span>
								{parking.parking_spot_address1 != ""?parking.parking_spot_address1+", ": null}
								{parking.parking_spot_address2 != ""?parking.parking_spot_address2+", ": null}
								{parking.city != ""?parking.city+", ": null}

								</span>
								
								<span>
								{parking.state != ""?parking.state+", ": null}
								{parking.country != ""?parking.country: null}
								</span>
								</>
							}
							</p>
						</div>

						<div className='call'>
							<i className='bi bi-telephone-fill'></i>
							<p>Service Call: {parking? "+"+parking.parking_country_code+" " +parking.parking_phone_number: null}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

SpotDirection.propTypes = {
	className: PropTypes.string.isRequired,
	setStep: PropTypes.func.isRequired,
	map: PropTypes.object,
	position: PropTypes.exact({
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired,
	}),
};

export default memo(SpotDirection);
