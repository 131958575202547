import { VIEW_DETAILS_PAGE1, VIEW_DETAILS_PAGE3, VIEW_DETAILS_PAGE2 } from "./ActionType";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
	switch (action.type) {
		case VIEW_DETAILS_PAGE1:
			return {...state, page1: action.page1};
		case VIEW_DETAILS_PAGE2:
			return {...state, page2: action.page2};
		case VIEW_DETAILS_PAGE3:
			return {...state, page3: action.page3};

		default:
			return state;
	}
};