import { VIEW_DETAILS } from "./ActionType";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
	switch (action.type) {
		case VIEW_DETAILS:
			return (state = action.payload);

		default:
			return state;
	}
};