import React, { memo, useCallback, useState, useRef, useEffect } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";
import PropTypes from "prop-types";

const SearchMapSection = ({ map, setPosition, setState, state ,data }) => {
	const [showDropdown, setShowDropDown] = useState(false);
	const dropdownRef = useRef(null);
	


	useEffect(() => {
		document.addEventListener("click", handleClick, true);
		return () => {
			document.addEventListener("click", handleClick, true);
		};
	}, [dropdownRef]);

	const handleClick = (e) => {
		// this is to check if ref is a sidebar for example or other div
		if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
			// !ref.current.contains(e.target) ===>  that ensures that the clicked div is toogle div or outsider div
			setShowDropDown(false);
		}
	};

	const handleChange = useCallback(
		(address) => {
			if (!map) return;

			setState((prevState) => ({ ...prevState, address }));

			// if there is no address to show
			if (!address) return setShowDropDown(false);

			// show drop down
			setShowDropDown(true);
		},
		[map, setState]
	);

	const handleSelect = useCallback(
		async (address) => {
			setShowDropDown(false);
			if (!map) return;

			let latLng = await geocodeByAddress(address).catch((error) => {
				return console.error("Error", error);
			});

			// extracting lat and lng from address
			const lat = latLng[0]?.geometry?.location?.lat();
			const lng = latLng[0]?.geometry?.location?.lng();
			const tempAddress = latLng[0]?.formatted_address;

			// setting address to input field
			setState((prevState) => ({ ...prevState, address: tempAddress }));
			map.setZoom(18);
			map.setCenter({ lat, lng });
			setPosition({ lat, lng });
		},
		[map, setState, setPosition]
	);
	useEffect(()=>{
	
	
		if (data!=null){
		    handleSelect(data)
		}
	},[data,handleSelect])

	return (
		<>
			{map ? (
				<PlacesAutocomplete
					value={state.address}
					onChange={handleChange}
					onSelect={handleSelect}
					>
					{({
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading,
					}) => (
						<div className='autoComplete__wrapper'>
							<div className='search__container'>
								<span>
									<i className='bi bi-search'></i>
								</span>
								<input
									id='autocomplete'
									type='text'
									{...getInputProps({
										placeholder: "Search here",
										className: "location-search-input",
									})}
								/>
							</div>

							<div
								ref={dropdownRef}
								className={
									showDropdown
										? "autocomplete-dropdown-container show"
										: "autocomplete-dropdown-container"
								}>
								{loading && <div >Loading...</div>}
								{suggestions.map((suggestion, i) => {
									const className = suggestion.active
										? "suggestion-item--active"
										: "suggestion-item";
									// inline style for demonstration purpose
									const style = suggestion.active
										? { backgroundColor: "#fafafa", cursor: "pointer" }
										: { backgroundColor: "#ffffff", cursor: "pointer" };
									return (
										<div
											{...getSuggestionItemProps(suggestion, {
												className,
												style,
											})}
											key={i}>
											<span>
												<i className='bi bi-geo-alt-fill'></i>
												{suggestion.description}
											</span>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</PlacesAutocomplete>
			) : (
				<div className='search__container'>
					<span>
						<i className='bi bi-search'></i>
					</span>
					<input
						id='autocomplete'
						type='text'
						placeholder='Search by location'
					/>
				</div>
			)}
		</>
	);
};

SearchMapSection.propTypes = {
	map: PropTypes.object,
	setPosition: PropTypes.func,
	setState: PropTypes.func,
	state: PropTypes.exact({
		address: PropTypes.string,
	}).isRequired,
};

export default memo(SearchMapSection);
