import React from "react"
import NoFound from '../assets/404/404.svg';


const NotFound = () => {
    return(
    <>
        <div className="col-md-12 404-container d-flex flex-column align-items-center justify-content-center" style={{minHeight:'80vh'}}>
            <div className="row">
                <div className="col-md-12">
                    <ul className="list list-inline d-inline-flex align-items-center list-unstyled">
                        <li className="list-inline-item main_font1__7shTE" style={{fontSize:'12rem'}}>4</li>
                        <li className="list-inline-item"><img style={{height:'12rem',transform:'translateY(10px)'}} src={NoFound} alt="404 not found" /></li>
                        <li className="list-inline-item main_font1__7shTE" style={{fontSize:'12rem'}}>4</li>
                    </ul>
                </div>
            </div>
            <div className="row">
            <div className="col-md-12 text-center">
                    <p className="main_font1__7shTE">Oops! Something isn't right</p>
                    <p className="text-secondary">Sorry, we can't find what you are looking for, <br />the page may not exist or has moved somewhere else</p>
                </div>
            </div>
            
        </div>


    </>
    
    
    )
}

export default NotFound