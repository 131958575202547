import React, { memo, useEffect, useCallback, useState } from "react";
import GoogleMaps from "./Map/GoogleMaps";
import { Loader } from "@googlemaps/js-api-loader";
import MyLocationMarker from "../../../assets/Icons/myLocation.svg";
import otherSpotPriceIcon from "../../../assets/Icons/Path 20551blue.svg";
import mySpotPriceIcon from "../../../assets/Icons/Path 20551.svg";
import { googleMapConfig, INITIAL_LOCATION } from "../../../config/app.config";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import SearchMapSection from "./Map/SearchMapSection";
import ParkingSpotDetailsMap from "./Map/ParkingSpotDetailsMap";
import axiosInstance from "../../../axios";
import dotenv from "dotenv";
import ListOfParkingSpot from "./Map/ListOfParkingSpot";
import SpotDirection from "./Map/SpotDirection";
import CalenderSection from "../../../components/CalenderSection/CalenderSection";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "../../../styles.css";
import HowItWorks from "./HowItWorks";
import toast from "react-hot-toast";

const DATAFORMATSPOTS = {
  id: null,
  parking_name: null,
  total_count_booked: null,
  total_cancele_booked: null,
  total_earned: null,
  total_ratings: null,
  likes: null,
  parking_country_code: null,
  parking_phone_number: null,
  parking_brief_discription: null,
  monthly: false,
  hourly: false,
  mon_hourly: null,
  tus_hourly: null,
  wed_hourly: null,
  thu_hourly: null,
  fri_hourly: null,
  sat_hourly: null,
  sun_hourly: null,
  monthly_rate: null,
  hourly_rate: null,
  is_your_parking_can_list_at_least_3_months: false,
  is_parking_unser_cctv: false,
  is_parking_on_deed_restricted_community: false,
  are_u_owner_of_parking: false,
  gated_core_required: false,
  add_gate_code: null,
  parking_instruction: null,
  is_active: false,
  pic_1: null,
  pic_2: null,
  pic_3: null,
  pic_4: null,
  BD_pic_1: null,
  BD_pic_2: null,
  BD_pic_3: null,
  BD_pic_4: null,
  loc_lat: null,
  loc_lon: null,
  parking_spot_address1: null,
  parking_spot_address2: null,
  country: null,
  city: null,
  state: null,
  zip_code: null,
  extension_report: null,
  distance: null,
  amenities: null,
  message: null,
  bookmarked_by: null,
  liked_by: null,
};

const DATA_PROCESSOR_SPOT = (data, setState, ext = {}, sp_msg = {}) => {
  let temp = [];

  for (const spot in data) {
    var obj = {};
    //console.log(data[spot])
    for (const key in DATAFORMATSPOTS) {
      if (key in data[spot]) {
        obj = { ...obj, [key]: data[spot][key] };
      } else {
        obj = { ...obj, [key]: null };
      }
    }
    // console.log("------",Object.keys(ext))
    if (Object.keys(ext).includes(String(obj.id))) {
      // console.log("------22",ext)
      obj.extension_report = ext[String(obj.id)];
    }
    if (Object.keys(sp_msg).includes(String(obj.id))) {
      // console.log("------22",sp_msg)
      obj.message = sp_msg[String(obj.id)];
    }
    temp.push(obj);
  }

  setState(temp);
};

function addMarker(props) {
  // Add the marker at the clicked location, and add the next-available label
  // from the array of alphabetical characters.

  return new window.google.maps.Marker({
    position: props.location,
    label: {
      text: props.label || "",
      color: props.color || "white",
      fontSize: props.fontSize || "18px",
      fontFamily: props.font || "ProximaNova-Regular",
    },
    map: props.map,
    icon: props.svg,
    draggable: props.dragable || false,
    animation: window.google.maps.Animation.DROP,
  });
}

const Searchparking = (props) => {
  const [position, setPosition] = useState({
    lat: 0.0,
    lng: 0.0,
  });
  const [showup, setshowup] = useState(true);
  const [spots, setSpots] = useState([]);
  const [bookmarkedSpots, setBookmarkedSpots] = useState([]);

  const [myMarker, setMyMarker] = useState();
  const [zoom, setZoom] = useState(18);

  const [spotMarkers, setSpotMarkers] = useState([]);

  const [step, setStep] = useState("list");
  const [spotIndex, setSpotIndex] = useState(null);

  const [mapCircle, setMapCircle] = useState(null);
  // const [bookmarkRef, setBookMarkRef] = useState(null);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  // global map instance
  const [map, setMap] = useState(null);

  const [state, setState] = useState({
    address: "",
  });

  const [val, setval] = useState(null);

  useEffect(() => {
    console.log(props.location.data, "asdfafasdfasd");
    if (props.location.data !== undefined) {
      setval(props.location.data);
    }
  }, []);
  // console.log("---spots", spots)
  function removeMarkers() {
    for (let i = 0; i < spotMarkers.length; i++) {
      spotMarkers[i].setMap(null);
    }
    setSpotMarkers([]);
  }

  const handleError = useCallback((error) => {
    let msg = "";
    switch (error.code) {
      case error.PERMISSION_DENIED:
        msg = "User does not want to display location.";
        break;
      case error.POSITION_UNAVAILABLE:
        msg = "Can't determine user's location.";
        break;
      case error.TIMEOUT:
        msg = "The request for geolocation info timed out.";
        break;
      case error.UNKNOWN_ERROR:
        msg = "An unknown error occurred.";
        break;

      default:
        msg = "";
    }

    alert(msg);
    // console.error(msg);
  }, []);

  const handleMyLocation = useCallback(
    (tempMap) => {
      if (!tempMap) return;
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            tempMap.setCenter({ lat, lng });

            setPosition({ lat, lng });
            // console.log("DDD");
          },
          handleError,
          options
        );
      } else {
        toast.error("Browser Doesn't Support Location");
      }
    },
    [handleError]
  );

  const handleMarkerDrag = (event) => {
    // console.log(event.latLng.lat(), event.latLng.lng())
    setPosition({
      ...position,
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const initMap = useCallback(
    (lat, lng) => {
      const location = new window.google.maps.LatLng(lat, lng);
      //maps
      let tempMap = new window.google.maps.Map(document.getElementById("map"), {
        disableDefaultUI: true,
        center: location,
        zoom: 15,
        minZoom: 10,
        maxZoom: 20,
        zoomControl: true,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
      });

      // add currentLocationButton
      const myLoc = document.createElement("button");
      myLoc.id = "myLocation";
      myLoc.classList.add("custom__location__button");
      // console.log(myLoc);

      // adding to view
      tempMap.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
        myLoc
      );
      myLoc.addEventListener("click", () => handleMyLocation(tempMap), true);

      const MY_LOCATION_MARKER = {
        url: MyLocationMarker,
        // This marker is 20 pixels wide by 32 pixels high.
        size: new window.google.maps.Size(100, 100),
        // The origin for this image is (0, 0).
        origin: new window.google.maps.Point(0, -20),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new window.google.maps.Point(50, 100),
      };

      const marker = new window.google.maps.Marker({
        position: location,
        map: tempMap,
        icon: MY_LOCATION_MARKER,
        zIndex: 2,
        optimized: true,
        animation: window.google.maps.Animation.DROP,
        draggable: true,
      });
      marker.addListener("dragend", (event) => handleMarkerDrag(event));
      setMyMarker(marker);

      // setMap
      setMap(tempMap);
    },
    [handleMyLocation]
  );

  // console.log("---", zoom)

  useEffect(() => {
    // adding free view space
    document.body.style.minWidth = "100%";
    const loader = new Loader(googleMapConfig);
    loader
      .load()
      .then(() => {
        initMap(INITIAL_LOCATION.lat, INITIAL_LOCATION.lng);

        // setting position
        setPosition(INITIAL_LOCATION);
      })
      .catch((err) => {
        alert(err.message);
      });

    return () => {
      document.body.style.minWidth = "450px";
    };
  }, [initMap]);

  useEffect(() => {
    if (!map) return;
    map.setCenter(position);
  }, [position]);

  useEffect(async () => {
    var formData = new FormData();
    // console.log((30000/2**(zoom))%10)
    formData.append("search", "");
    formData.append("radious", 10); //parseInt((30000/2**(zoom))%10))//parseInt(30000/2**(zoom)))
    formData.append("lat", position.lat);
    formData.append("long", position.lng);
    formData.append("start_time", startTime);
    formData.append("end_time", endTime);
    formData.append("hourly", false);
    formData.append("monthly", false);
    formData.append("amenities_filter", null);

    await axiosInstance
      .post("/consumer_dash/parking_spot_search_consumer_api", formData)
      .then(async (response) => {
        //console.log(response.data)
        // console.log("-----report", response.data.response.extension_report)
        let directionsService = new window.google.maps.DirectionsService();
        let directionsRenderer = new window.google.maps.DirectionsRenderer();
        //directionsRenderer.setMap(map);
        let data = response.data.response.user || [];
        let route = {};
        for (var i in data) {
          route = {
            origin: position,
            destination: { lat: data[i].loc_lat, lng: data[i].loc_lon },
            travelMode: "DRIVING",
          };

          await directionsService.route(route, function (response, status) {
            // anonymous function to capture directions
            if (status !== "OK") {
              // console.log('Directions request failed due to ' + status);
              return;
            } else {
              //directionsRenderer.setDirections(response); // Add route to the map
              var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
              if (!directionsData) {
                // console.log('Directions request failed');
                return;
              } else {
                // console.log(" Driving distance is " + directionsData.distance.text + " (" + directionsData.duration.text + ").");
                data[i]["distance"] = directionsData.distance.text;
              }
            }
          });
        }
        DATA_PROCESSOR_SPOT(
          data,
          setSpots,
          response.data.response.extension_report,
          response.data.response.spot_messages
        );
      })
      .catch((err) => {
        // console.log(err)
        toast.error("Something went wrong!");
      });

    var formData = new FormData();
    formData.append("start_time", startTime);
    formData.append("end_time", endTime);

    await axiosInstance
      .post("/consumer_dash/bookmarked_by_time", formData)
      .then(async (response) => {
        // console.log(response.data)
        let directionsService = new window.google.maps.DirectionsService();
        let directionsRenderer = new window.google.maps.DirectionsRenderer();
        //directionsRenderer.setMap(map);
        let data = response.data.response.user || [];
        let route = {};
        for (var i in data) {
          route = {
            origin: position,
            destination: { lat: data[i].loc_lat, lng: data[i].loc_lon },
            travelMode: "DRIVING",
          };

          await directionsService.route(route, function (response, status) {
            // anonymous function to capture directions
            if (status !== "OK") {
              // console.log('Directions request failed due to ' + status);
              return;
            } else {
              //directionsRenderer.setDirections(response); // Add route to the map
              var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
              if (!directionsData) {
                // console.log('Directions request failed');
                return;
              } else {
                // console.log(" Driving distance is " + directionsData.distance.text + " (" + directionsData.duration.text + ").");
                data[i]["distance"] = directionsData.distance.text;
              }
            }
          });
        }
        DATA_PROCESSOR_SPOT(
          data,
          setBookmarkedSpots,
          response.data.response.extension_report,
          response.data.response.spot_messages
        );
      })
      .catch((err) => {
        // console.log(err)
        toast.error("Something went wrong!");
      });
  }, [position, zoom, state, step, startTime, endTime]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    removeMarkers();
    let m = [];
    spots.map((spot, i) => {
      //console.log(spot)
      let temp = addMarker({
        location: { lat: spot.loc_lat, lng: spot.loc_lon },
        map: map,
        svg: otherSpotPriceIcon,
        label: spot.hourly_rate ? "$" + String(spot.hourly_rate) : null,
        fontSize: "16px",
      });
      temp.addListener("mouseover", (m) => {
        temp.setIcon(mySpotPriceIcon);
        setSelected(i);
      });
      temp.addListener("mouseout", (m) => {
        temp.setIcon(otherSpotPriceIcon);
        setSelected(null);
      });
      temp.addListener("click", (m) => {
        // console.log("----clicked", i)
        temp.setIcon(mySpotPriceIcon);
        setSpotIndex(i);
      });
      m.push(temp);
    });

    setSpotMarkers([...spotMarkers, ...m]);
  }, [spots]);

  useEffect(() => {
    if (!map) return;
    map.addListener("zoom_changed", () => {
      setZoom(map.getZoom());
    });
  }, [map]);

  useEffect(() => {
    if (!map || !zoom) {
      return;
    }
    if (mapCircle) {
      mapCircle.setMap(null);
    }

    setMapCircle(
      new window.google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.1,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.18,
        map: map,
        center: position,
        radius: 1.5 * ((30000 / 2 ** zoom) % 10) * 1000, //(30000/2**(zoom))*1000//Math.sqrt(citymap[city].population) * 100,
      })
    );
  }, [zoom, position]);

  const handleBookmark = async (id) => {
    if (!id) {
      return;
    }
    // console.log(id)
    let formData = new FormData();
    formData.append("parking_id", parseInt(id));

    await axiosInstance
      .post("/consumer_dash/bookmarked", formData)
      .then((response) => {
        // console.log("book mark", response.data)
        setStep("list");
        alert("Update Successfull");
      })
      .catch((err) => {
        toast.error("Something went wrong!");

        // console.log("bookmark", err.response.data)
      });
  };
  const Dropup = () => {
    if (showup) {
      setshowup(false);
    } else {
      setshowup(true);
    }
  };

  return (
    <>
      <div className="homePage">
        <section className="top row m-0 justify-content-center align-items-center">
          <div className=" col-12 col-sm-5 col-md-4 col-lg-4 col-xl-3 p-1">
            <SearchMapSection
              map={map}
              setState={setState}
              state={state}
              setPosition={setPosition}
              data={val}
            />
          </div>

          <div className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-9 p-1">
            <CalenderSection
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              today={true}
            />
          </div>
        </section>

        <section className={showup ? "side" : "sideup side"}>
          <div className="content">
            <>
              <ListOfParkingSpot
                className={
                  step === "list"
                    ? "listOfParkingSpot active"
                    : "listOfParkingSpot"
                }
                setStep={setStep}
                parkings={spots}
                saved={bookmarkedSpots}
                setSpotIndex={setSpotIndex}
                handleBookmark={handleBookmark}
                selected={selected}
                startTime={startTime}
                endTime={endTime}
                showup={showup}
                dropup={Dropup}
              />
            </>

            <>
              <ParkingSpotDetailsMap
                className={
                  step === "spot-details"
                    ? "parkingSpot__details active"
                    : "parkingSpot__details"
                }
                setStep={setStep}
                parking={spots[spotIndex]}
                handleBookmark={handleBookmark}
              />
            </>

            <>
              <SpotDirection
                map={map}
                position={position}
                className={
                  step === "directions"
                    ? "spot__direction active"
                    : "spot__direction"
                }
                setStep={setStep}
                parking={spots[spotIndex]}
              />
            </>
          </div>
        </section>
      </div>
      <GoogleMaps map={map} position={position} marker={myMarker} />
    </>
  );
};

export default Searchparking;
