// marker icons
import BoldMarkerIcon from "../assets/Icons/markerBold.svg";
import Marker from "../assets/Icons/marker.svg";
import MyLocationMarker from "../assets/Icons/myLocation.svg";
import dotenv from 'dotenv';
import toast from "react-hot-toast";
dotenv.config({path: '/server/.env'});
// export const BOLD_IMAGE = {
// 	url: BoldMarkerIcon,
// 	// This marker is 20 pixels wide by 32 pixels high.
// 	size: new window.google.maps.Size(60, 80),
// 	// The origin for this image is (0, 0).
// 	origin: new window.google.maps.Point(0, -5),
// 	// The anchor for this image is the base of the flagpole at (0, 32).
// 	anchor: new window.google.maps.Point(35, 85),
// };

// export const Marker_IMAGE = {
// 	url: Marker,
// 	// This marker is 20 pixels wide by 32 pixels high.
// 	size: new window.google.maps.Size(40, 60),
// 	// The origin for this image is (0, 0).
// 	origin: new window.google.maps.Point(0, 0),
// 	// The anchor for this image is the base of the flagpole at (0, 32).
// 	anchor: new window.google.maps.Point(25, 60),
// };

// export const MY_LOCATION_MARKER = {
// 	url: MyLocationMarker,
// 	// This marker is 20 pixels wide by 32 pixels high.
// 	size: new window.google.maps.Size(100, 100),
// 	// The origin for this image is (0, 0).
// 	origin: new window.google.maps.Point(0, -20),
// 	// The anchor for this image is the base of the flagpole at (0, 32).
// 	anchor: new window.google.maps.Point(50, 100),
// };

export const REACT_FLAG_CDN =
	"https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/";

//google map config
export const googleMapConfig = {
	apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY, // need to add on env
	version: "weekly",
	libraries: ["places", "directions", "geometry"],
};

export const dashedPolyLineMap = {
	icons: [
		{
			icon: {
				path: "M 0,-1 0,1",
				offset: "0",
				scale: 5,
				fillOpacity: 1,
				fillColor: "#e45682",
				strokeOpacity: 0.8,
				strokeColor: "#e45682",
				strokeWeight: 2,
			},
			repeat: "20px",
		},
	],
	strokeColor: "#000000",
	strokeOpacity: 0,
	strokeWeight: 5,
};

export const INITIAL_LOCATION = { lat: 40.7127753, lng: -74.0059728 };

export const handleCopyText = async (text) => {
	try {
	  await navigator.clipboard.writeText(text);
	  toast		.success("Coupon Code copied succesfully");
	  return true;
	} catch (err) {
	  console.error("Failed to copy text: ", err);
	  toast.error("Failed to copy Referral Code");
	  return false;
	}
  };