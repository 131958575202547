import React, { memo, useEffect } from "react";
import PropsTypes from "prop-types";

// marker icons
import BoldMarkerIcon from "../../../../assets/Icons/markerBold.svg";
import Marker from "../../../../assets/Icons/marker.svg";
import MyLocationMarker from "../../../../assets/Icons/myLocation.svg";

const GoogleMaps = ({ map, position, marker }) => {
	// after map renders

	// setup markers
	useEffect(() => {
		if (!map) return;
		if (!position.lat && !position.lng) return;

		const boldImage = {
			url: BoldMarkerIcon,
			// This marker is 20 pixels wide by 32 pixels high.
			size: new window.google.maps.Size(60, 80),
			// The origin for this image is (0, 0).
			origin: new window.google.maps.Point(0, -5),
			// The anchor for this image is the base of the flagpole at (0, 32).
			anchor: new window.google.maps.Point(35, 85),
		};

		const image = {
			url: MyLocationMarker,
			// This marker is 20 pixels wide by 32 pixels high.
			size: new window.google.maps.Size(40, 60),
			// The origin for this image is (0, 0).
			origin: new window.google.maps.Point(0, 0),
			// The anchor for this image is the base of the flagpole at (0, 32).
			anchor: new window.google.maps.Point(25, 60),
		};

		const location = new window.google.maps.LatLng(position.lat, position.lng);

		// let m1 = new window.google.maps.Marker({
		// 	position: position,
		// 	label: {
		// 		text: "$13",
		// 		color: "white",
		// 		fontSize: "16px",
		// 		fontFamily: "ProximaNova",
		// 	},
		// 	map,
		// 	icon: image,
		// 	zIndex: 0,
		// 	optimized: true,
		// 	animation: window.google.maps.Animation.DROP,
		// 	title: "<h2>Hello</h2>",
		// });

		// let m2 = new window.google.maps.Marker({
		// 	position: position,
		// 	label: {
		// 		text: "$13",
		// 		color: "white",
		// 		fontSize: "16px",
		// 		fontFamily: "ProximaNova",
		// 	},
		// 	map,
		// 	icon: boldImage,
		// 	zIndex: 1,
		// 	optimized: true,
		// 	animation: window.google.maps.Animation.DROP,
		// 	title: "<h6>This is rock</h6>",
		// });

		console.log(position);

		marker.setAnimation(window.google.maps.Animation.BOUNCE);
		marker.setPosition(location);

		// clearing marker animation
		setTimeout(() => {
			marker.setAnimation(null);
		}, 400);

		// info window
		const infoWindow = new window.google.maps.InfoWindow();

		// // adding marker listener on click
		// m3.addListener("click", () => {
		// 	infoWindow.close();
		// 	infoWindow.setContent(m3.getTitle());
		// 	infoWindow.open(map, m3);
		// });
	}, [map, position, marker]);

	return (
		<div className='map__wrapper'>
			<div id='map'></div>
		</div>
	);
};

GoogleMaps.propTypes = {
	map: PropsTypes.object,
	position: PropsTypes.exact({
		lat: PropsTypes.number,
		lng: PropsTypes.number,
	}).isRequired,
};

export default memo(GoogleMaps);
