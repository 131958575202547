import React, { useReducer } from "react";
import Reducer from "./reducer";
import Context from "./context";

const ForgotPass = (props) => {
  const [passData, dispatchPassData] = useReducer(Reducer, null);

  return (
    <Context.Provider value={{ passData, dispatchPassData }}>
      {props.children}
    </Context.Provider>
  );
};

export default ForgotPass;