import React, { memo, useEffect, useState, useRef, useCallback } from "react";
import MOMENT from "moment";

// custom hooks
import useAutoHide from "../../hooks/useAutoHide";

// component
import Calender from "./Calender";
import TimePicker from "./TimePicker";

// Icons
import CalenderIcon from "../../assets/Icons/calender2x.png";
import {CONVERTMINUTESTOHHMM} from "./../Tools"

const CalenderSection = ({setStartTime, setEndTime}) => {
	const startDateRef = useRef();
	const lastDateRef = useRef();

	const [showStartDate, setShowStartDate] = useAutoHide(
		startDateRef,
		"calender1"
	);
	const [showEndDate, setShowEndDate] = useAutoHide(lastDateRef, "calender2");

	// initial date state
	const [startDate, setStartDate] = useState({
		date: MOMENT(new Date()).clone(),
		finalDate: MOMENT(new Date()).clone(),
		hour: String(Number(new Date().getHours())%12),
		min: new Date().getMinutes(),
		am: Number(new Date().getHours())<12,
	});
	const [endDate, setEndDate] = useState({
		date: MOMENT(new Date()).clone(),
		finalDate: MOMENT(new Date()).clone(),
		hour: String(Number(new Date().getHours())%12),
		min: new Date().getMinutes(),
		am: Number(new Date().getHours())<12,
	});

	// start date arrows
	const handleMonthSubtractForStartDate = useCallback(() => {
		setStartDate((prevState) => ({
			...prevState,
			date: prevState.date.subtract(1, "month").clone(),
		}));
	}, []);

	const handleMonthAddForStartDate = useCallback(() => {
		setStartDate((prevState) => ({
			...prevState,
			date: prevState.date.add(1, "month").clone(),
		}));
	}, []);

	// end date arrows
	const handleMonthSubtractForEndDate = useCallback(() => {
		setEndDate((prevState) => ({
			...prevState,
			date: prevState.date.subtract(1, "month").clone(),
		}));
	}, []);

	const handleMonthAddForEndDate = useCallback(() => {
		setEndDate((prevState) => ({
			...prevState,
			date: prevState.date.add(1, "month").clone(),
		}));
	}, []);

	// handle final date time
	const handleFinalStartDateTime = useCallback(() => {
		let hourFormat = startDate.am ? +startDate.hour : +startDate.hour + 12;

		setShowStartDate(false)
		hourFormat =
			hourFormat === 12 /*am*/ ? 0 : hourFormat === 24 /*pm*/ ? 12 : hourFormat;

		setStartDate((prevState) => ({
			...prevState,
			finalDate: MOMENT(
				new Date(
					+prevState.date.format("YYYY"),
					+prevState.date.format("M") - 1,
					+prevState.date.format("D"),
					+hourFormat,
					+prevState.min,
					0
				)
			),
		}));
	}, [startDate]);

	useEffect(()=>{
		console.log(startDate, "----", endDate)
		let d1 = startDate.date
		let d2 =endDate.date
		d1.set({'hour': startDate.am? (startDate.hour % 12): 12 + (startDate.hour % 12), 'minute': startDate.min, 'second': '00'})
		d2.set({'hour': endDate.am? (endDate.hour % 12): 12 + (endDate.hour % 12), 'minute': endDate.min,'second': '00'})
		console.log(d1, "d1----d2", d2)

		setStartTime(d1.format("YYYY-MM-DDTHH:mm:ss")+"Z")
		setEndTime(d2.format("YYYY-MM-DDTHH:mm:ss")+"Z")
	}, [startDate, endDate])

	const handleFinalEndDateTime = useCallback(() => {
		setShowEndDate(false)
		setEndDate((prevState) => ({
			...prevState,
			finalDate: MOMENT(
				new Date(
					+prevState.date.format("YYYY"),
					+prevState.date.format("M") - 1,
					+prevState.date.format("D"),
					+prevState.hour +
						/*This is to convert am to pm*/ (prevState.am ? 0 : 12),
					+prevState.min,
					0
				)
			),
		}));
	}, []);

	return (
		<>
			<div className='calenderSection'>
				{/* Start Date */}
				<div className='schedule'>
					<label htmlFor=''>Arrival :</label>
					<p>{startDate.finalDate.format("ddd D MMM, hh:mm A")}</p>
					<img
						id='calender1'
						src={CalenderIcon}
						alt='CalenderIcon'
						onClick={() => setShowStartDate((prevState) => !prevState)}
					/>

					<div
						ref={startDateRef}
						className={
							showStartDate ? "wrapper__calender show" : "wrapper__calender"
						}>
						<p className='heading'>Arrival Time</p>

						<div className='head'>
							<label htmlFor=''>Date</label>

							<p>
								<span>{startDate.date.format("MMMM")}</span>
								<span>{startDate.date.format("YYYY")}</span>
							</p>

							<span>
								<i
									className='bi bi-chevron-left'
									onClick={handleMonthSubtractForStartDate}></i>
								<i
									className='bi bi-chevron-right'
									onClick={handleMonthAddForStartDate}></i>
							</span>
						</div>

						<Calender
							key={1}
							setViewCalender={setStartDate}
							viewCalender={startDate}
						/>

						<div className='timer'>
							<label htmlFor=''>Time</label>

							<>
								<TimePicker
									className='input__wrapper'
									setTime={setStartDate}
									time={startDate}
								/>
							</>

							<div className='am__pm'>
								<p
									className={startDate.am ? "active" : ""}
									onClick={() =>
										setStartDate((prevState) => ({
											...prevState,
											am: true,
										}))
									}>
									AM
								</p>
								<p
									className={!startDate.am ? "active" : ""}
									onClick={() =>
										setStartDate((prevState) => ({
											...prevState,
											am: false,
										}))
									}>
									PM
								</p>
							</div>
						</div>

						<div className='footer__group'>
							<button className='appButton' onClick={handleFinalStartDateTime}>
								Set Arrival Time
							</button>
						</div>
					</div>
				</div>

				<div className='divider'>
					<p>Total Time</p>
					{/* <span>
						{MOMENT(endDate.date.diff(startDate.date, "hours")).format(
							"h[h] m[m]"
						)}
					</span> */}
					<span>
						{CONVERTMINUTESTOHHMM(endDate.date.diff(startDate.date, "minutes"))}
					</span>
				</div>

				{/* End Date */}
				<div className='schedule '>
					<label htmlFor=''>Checkout :</label>
					<p>{endDate.finalDate.format("ddd D MMM, hh:mm A")}</p>
					<img
						id='calender2'
						src={CalenderIcon}
						alt='CalenderIcon'
						onClick={() => setShowEndDate((prevState) => !prevState)}
					/>

					<div
						ref={lastDateRef}
						className={
							showEndDate ? "wrapper__calender show" : "wrapper__calender"
						}>
						<p className='heading'>Checkout Time</p>

						<div className='head'>
							<label htmlFor=''>Date</label>

							<p>
								<span>{endDate.date.format("MMMM")}</span>
								<span>{endDate.date.format("YYYY")}</span>
							</p>

							<span>
								<i
									className='bi bi-chevron-left'
									onClick={handleMonthSubtractForEndDate}></i>
								<i
									className='bi bi-chevron-right'
									onClick={handleMonthAddForEndDate}></i>
							</span>
						</div>

						<Calender
							key={2}
							setViewCalender={setEndDate}
							viewCalender={endDate}
						/>

						<div className='timer'>
							<label htmlFor=''>Time</label>

							<>
								<TimePicker
									className='input__wrapper'
									setTime={setEndDate}
									time={endDate}
								/>
							</>

							<div className='am__pm'>
								<p
									className={endDate.am ? "active" : ""}
									onClick={() =>
										setEndDate((prevState) => ({
											...prevState,
											am: true,
										}))
									}>
									AM
								</p>
								<p
									className={!endDate.am ? "active" : ""}
									onClick={() =>
										setEndDate((prevState) => ({
											...prevState,
											am: false,
										}))
									}>
									PM
								</p>
							</div>
						</div>

						<div className='footer__group'>
							<button className='appButton' onClick={handleFinalEndDateTime}>
								Set Checkout Time
							</button>
						</div>
					</div>
				</div>
			</div>

			<div
				className={
					showEndDate || showStartDate ? "backdrop__pb show" : "backdrop__pb"
				}></div>
		</>
	);
};

export default memo(CalenderSection);
