import dotenv from 'dotenv';
import { React, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Android from '../../../assets/home/android.svg';
import Calendar from '../../../assets/home/calendar.svg';
import Clock from '../../../assets/home/clock.svg';
import IOS from '../../../assets/home/ios.svg';
import LeftQuote from '../../../assets/home/left-quote.svg';
import Mobile from '../../../assets/home/mobile.svg';
import RightQuote from '../../../assets/home/right-quote.svg';
import How from '../../../assets/JSON-Gifs/GIF/1.Introduction3Steps.json';
import Car from '../../../assets/JSON-Gifs/GIF/2.BecomeProvider 3.json';
import temploader from "../../../assets/JSON-Gifs/GIF/25.loading.json";
import axiosInstance from '../../../axios';
import LottieGIF from '../../../components/lottieComponent';
import ParInput from '../../../usable/form/ParInput';
import FormValidator from '../../../usable/FormValidator';
import './about-us.css';


dotenv.config({path: '/server/.env'});
let autoComplete;

const loadScript = (url, callback) => {
    
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
autoComplete = new window.google.maps.places.Autocomplete(
autoCompleteRef.current
);
autoComplete.setFields(["address_components", "formatted_address"]);
autoComplete.addListener("place_changed", () =>
handlePlaceSelect(updateQuery)
);
}

async function handlePlaceSelect(updateQuery) {
const addressObject = autoComplete.getPlace();
const query = addressObject.formatted_address;
updateQuery(query);
}

function HowItWorks(){
    const history = useHistory()
const [query, setQuery] = useState("");
const autoCompleteRef = useRef(null);

useEffect(() => {
	loadScript(
	`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`,
	() => handleScriptLoad(setQuery, autoCompleteRef)
	);
}, []);

const Urlsender=()=>{
    if (query!==''){
        history.push({pathname:'search-parking', data:query})
    }
}

const validator = new FormValidator(
				[
					{
						field: 'first_name',
						method: 'isEmpty',
						validWhen: false,
						message: 'First name is required'
					},
					{
						field: 'last_name',
						method: 'isEmpty',
						validWhen: false,
						message: 'Last name is required'
					},
					{
						field: 'email',
						method: 'isEmail',
						validWhen: true,
						message: 'Not a valid email'
					}
				])

		const [state, setState] = useState({
						first_name: '',
						last_name: '',
						email: '',
						message: '',
						validation: validator.valid(),
						loading: false,
						address: "",
					})

	const valInput = (type) => {
        const val = validator.inputValidator(type, state);
        setState((prev) => ({ ...prev, validation: val }));
    }

    const handleSubmit = () => {
        console.log(state)
        const validation = validator.validate(state);
        setState((prev) => ({ ...prev, validation: validation }));
        if (validation.isValid) {
            setState((prev) => ({...prev, loading: true }));

            const { first_name, last_name, email, message } = state

            var data = new FormData();
            data.set("first_name", first_name);
            data.set("last_name", last_name);
            data.set("email", email);
            data.set("mesg", message)
            axiosInstance.post("/provider_dash/contect_us_pro", data)
                .then(() => {
                    setState({
						first_name: '',
						last_name: '',
						email: '',
						message: '',
						validation: validator.valid(),
						loading: false,
						address: "",
					});
                })
                .catch(() => {
                    setState((prev) => ({...prev, loading: false }));
                })
        }
    }

    const handleChange = (event) => {
        let name = event.target.name;
        // setState({ [event.target.name]: event.target.value }, () => {
        //     valInput(name)
        // })
        setState((prev) => ({...prev, [event.target.name]: event.target.value})  )
        valInput(name)
    }




  return (
    <>
          {false && <LottieGIF json={temploader} loop={true} height="8rem" width="8rem"></LottieGIF>}
          <Helmet>
              <meta charSet="utf-8" />
              <title>Parking Bud About Us</title>
              <link rel="canonical" href="http://acropolisinfotech.com/" />
              <meta name="description" content='parking bud provider panel ' />
          </Helmet>
          <div className='container header d-flex flex-wrap'>
              <div className='col-md-6 d-flex flex-column justify-content-center'>
                  <h3 className='text-blue'>Your parking best friend</h3>

                  <p className=' text-secondary'>
                      Never drive around for a parking spot again. Parking Bud makes it simple to
                      find, book and pay for parking. Reserve your spots in advance and worry less.</p>
                  <p className='text-secondary'>Let's find your perfect parking spot.</p>
                  <p className='position-relative mb-0'>
                      <input type="text"
					  ref={autoCompleteRef}
					  onChange={event => setQuery(event.target.value)}
					  value={query}
					   className='form-control mb-3 rounded-10' placeholder='Search by location or place' />
					  <i className="bi bi-search search-button" onClick={Urlsender}></i>
					  
                  </p>
                  <div className=' d-flex justify-content-center'>
                      <img className='app-store-image mr-2' src={Android} alt="Android" />
                      <img className='app-store-image' src={IOS} alt="IOS" />
                  </div>
              </div>
              <div className='col-md-6 d-flex justify-content-center'>
                  <img className='mobile-image' src={Mobile} alt="Parking bud" />
              </div>
          </div>
          <div className='row light-blue-gradient m-0'>
              <div className='container p-4'>
                  <h3 className='text-blue mb-4 mt-2'>How It Works</h3>
                  <div className='d-flex justify-content-center'>
                      <LottieGIF json={How} loop={false}  ></LottieGIF></div>
                  <div className='row m-0 text-center mt-2'>
                      <div className='col-md-4'>
                          <p className='text-blue'>Find a Place</p>
                          <p className=''>Quickly fix your eyes on the perfect spot.</p>
                          <p className=''>Locate your parking spot based on convenience and needs in just a glance</p>
                      </div>
                      <div className='col-md-4'>
                          <p className='text-blue'>Reserve It</p>
                          <p className=''>It's fast and easy to book and pay for parking!</p>
                          <p className=''>Quit worrying about losing your parking spot
                              Book it in just a click and the work is over</p>
                      </div>
                      <div className='col-md-4'>
                          <p className='text-blue'>Park Vehicle</p>
                          <p className=''>Find your perfect spot waiting for you.</p>
                          <p className=''>The app help you navigate to your destination park hassle-free.</p>
                      </div>
                  </div>
              </div>
          </div>
		  <div className='row m-0'>
                    <div className='container text-center'>
                        <h3 className='text-blue mt-4'>Booking Options</h3>
                        <p className='text-secondary'>Parking Bud works for your comfort, which means there's an option perfect for everyone.</p>
                        <div className='row m-0'>
                            <div className='p-3 col-md-6 d-flex'>
                            <div className=' universalbox'>
                                <img src={Clock} alt="Clock" />
                                <h5 className='text-blue'>Hourly</h5>
                                <p className=''>
                                    Hourly is the perfect option for those on the
                                    move - enter and exit at specified hours for
                                    cheaper booking rates.
                                </p>
                                <div className='row m-0 justify-content-center'><p className='text-secondary mr-1'>Click here to read </p> <a href=''>Hourly Parking Terms</a></div>

                            </div>
                            </div>
                            <div className='p-3 col-md-6 d-flex '>
                            <div className='universalbox'>
                                <img src={Calendar} alt="Calendar" />
                                <h5 className='text-blue'>Monthly</h5>
                                <p className=''>Renting some space, or working an intermship?
                                    This one Is perfect fyou need 1o ensure.
                                    space for your ca for an entre month.</p>
                                <div className='row m-0 justify-content-center'><p className='text-secondary mr-1' >Click here to read</p> <a href=''>Monthly Parking Terms</a></div>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
				<div className='row m-0 light-blue-gradient'>
                    <div className='container'>
                        <div className='row m-0 p-4'>
                            <div className='col-md-6 text-center'>
                                <LottieGIF json={Car} loop={false} height="15rem" width="15rem"></LottieGIF>
                            </div>
                            <div className='col-md-6 p-3 d-flex flex-column align-items-center justify-content-center'>
                                <h3 className='text-blue'>Let's be (parking) buddies!</h3>
                                <p className='text-center'>
                                    Got a garage to fill or a business to register? We don't mind how big or small you are. If you would like to earn from your free parking spot, get in touch.</p>
                                <button type="button" className='btn mt-2 mb-2 text-white gradient-button' onClick={() => history.push('/rent-your-space')}>Register Your Parking Spot</button>
                            </div>
                        </div>
                    </div>

                </div>
				<div className='row m-0 p-4'>
                    <div className='col-md-12 text-center'>
                        <h3 className='text-blue mb-4'>A lot of happy users!</h3>
                    </div>
                    <div className='col-md-12 position-relative'>
                        <div id="demo" className="carousel slide" data-ride="carousel">
                <ul className="carousel-indicators">
                    <li data-target="#demo" data-slide-to="0" className="active"></li>
                    <li data-target="#demo" data-slide-to="1"></li>
                    <li data-target="#demo" data-slide-to="2"></li>
                </ul>
                <div className="carousel-inner text-center p-4">
                    <div className="carousel-item p-4 active">
                        <img className='left-quote' src={LeftQuote} alt="Left Quote" />
                        <p className='text-secondary h5'>
                            Using Parking bud was awesome.l completely forgot how much of a hassle parking gets and was in a 3
                            rush...Using ParkWhiz, | found a spot and was there within seconds!
                        </p>
                        <h5 className='text-blue'>Ashley C.</h5>
                        <img className='right-quote' src={RightQuote} alt="Right Quote" />
                    </div>
                    <div className="carousel-item p-4">
                        <img className='left-quote' src={LeftQuote} alt="Left Quote" />
                        <p className='text-secondary h5'>Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text</p>
                        <h5 className='text-blue'>Author</h5>
                        <img className='right-quote' src={RightQuote} alt="Right Quote" />
                    </div>
                    <div className="carousel-item p-4">
                        <img className='left-quote' src={LeftQuote} alt="Left Quote" />
                        <p className='text-secondary h5'>Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text
                            Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text</p>
                        <h5 className='text-blue'>Author</h5>
                        <img className='right-quote' src={RightQuote} alt="Right Quote" />
                    </div>
                </div>

            </div>
                    </div>
                </div>
				<div className='row m-0 footer-blue'>
                    <div className='container'>
                        <div className='row m-0 p-4'>
                            <div className='col-md-3'>
                                <h4 className='text-white h6'>Get To Know Us </h4>
                                <ul className='list list-unstyled text-secondary'>
                                    <li><Link className='par-link' to="/">Home </Link></li>
                                    <li><Link className='par-link' to="/terms-conditions">Terms of Use</Link></li>
                                    <li><Link className="par-link" to="/about-us?search=privacy">Privacy Policy</Link></li>
                                    <li> <Link className='par-link' to="/about-us">About Us</Link></li>
                                </ul>
                            </div>
                            <div className='col-md-3'>
                                <h4 className='text-white h6'>Support</h4>
                                <ul className='list list-unstyled text-secondary'>
                                    <li><Link className='par-link' to="/contact-us">Contact Us</Link></li>
                                    <li><Link className="par-link" to="/about-us?search=faq">Help & FAQa</Link></li>
                                    <li>Search Tips</li>
                                    <li>Working With Parking Bud</li>
                                    <li>Testimonial</li>
                                </ul>
                            </div>
                            <div className='col-md-3'>
                                <h4 className='text-white h6'>Social</h4>
                                <ul className='list list-unstyled text-secondary'>
                                    <li>Facebook</li>
                                    <li>Twitter</li>
                                    <li>Inspire Blog</li>
                                </ul>
                            </div>
                            <div className='col-md-3'>
                                <h4 className='text-white h6 mb-4'>Have something to say?</h4>
                                <div className='row m-0'>
                                    <ParInput
                                        className="col-md-12 form-group"
                                        placeholder="First Name"
                                        name="first_name"
                                        inputType="text"
                                        inputID="first_name"
                                        value={state.first_name}
                                        required={true}
                                        onChange={handleChange}
                                        inputClass="form-control par-input bg-white text-secondary w-auto"
                                        errorMsg={state?.validation?.first_name?.message || null}
                                    />

                                    <ParInput
                                        className="col-md-12 form-group"
                                        placeholder="Last Name"
                                        name="last_name"
                                        inputType="text"
                                        inputID="last_name"
                                        value={state.last_name}
                                        onChange={handleChange}
                                        inputClass="form-control par-input bg-white text-secondary w-auto"
                                        required={true}
                                        errorMsg={state?.validation?.last_name?.message || null}
                                    />
                                    <ParInput
                                        className="col-md-12 form-group"
                                        placeholder="Email"
                                        name="email"
                                        inputType="email"
                                        inputID="email"
                                        value={state.email}
                                        onChange={handleChange}
                                        required={true}
                                        inputClass="form-control par-input bg-white text-secondary w-auto"
                                        errorMsg={state?.validation?.email?.message || null}
                                    />

                                    <ParInput
                                        className="col-md-12 form-group"
                                        placeholder="Message"
                                        name="message"
                                        inputType="text"
                                        inputID="mesg"
                                        value={state.message}
                                        onChange={handleChange}
                                        inputClass="form-control par-input bg-white text-secondary w-auto"
                                    />
                                </div>
                                <div className='row d-flex justify-content-center m-0'>
                                    <button onClick={handleSubmit} type="button" className="btn text-white mt-3 text-uppercase">Send</button>
                                </div>
                            </div>
                            <div className='col-md-12 mt-4 text-center'>
                                <p className='text-secondary mb-0'>Parking Bud &copy; | A Visual Analytics Solution LLC Product | All Rights Reserved</p>
                            </div>
                        </div>
                    </div>

                 </div>
      </>
  )
}

export default HowItWorks







// class HowItWorks extends React.Component {
//     constructor(props) {
//         super(props);
//         this.validator = new FormValidator(
//             [
//                 {
//                     field: 'first_name',
//                     method: 'isEmpty',
//                     validWhen: false,
//                     message: 'First name is required'
//                 },
//                 {
//                     field: 'last_name',
//                     method: 'isEmpty',
//                     validWhen: false,
//                     message: 'Last name is required'
//                 },
//                 {
//                     field: 'email',
//                     method: 'isEmail',
//                     validWhen: true,
//                     message: 'Not a valid email'
//                 }
//             ])
//         this.state = {
//             first_name: '',
//             last_name: '',
//             email: '',
//             message: '',
//             validation: this.validator.valid(),
//             loading: false,
//             address: "",
//         }
        
//         this.DATAFORMATSPOTS = {
//             id: null,
//             parking_name: null,
//             total_count_booked: null,
//             total_cancele_booked: null,
//             total_earned: null,
//             total_ratings: null,
//             likes: null,
//             parking_country_code: null,
//             parking_phone_number: null,
//             parking_brief_discription: null,
//             monthly: false,
//             hourly: false,
//             mon_hourly: null,
//             tus_hourly: null,
//             wed_hourly: null,
//             thu_hourly: null,
//             fri_hourly: null,
//             sat_hourly: null,
//             sun_hourly: null,
//             monthly_rate: null,
//             hourly_rate: null,
//             is_your_parking_can_list_at_least_3_months: false,
//             is_parking_unser_cctv: false,
//             is_parking_on_deed_restricted_community: false,
//             are_u_owner_of_parking: false,
//             gated_core_required: false,
//             add_gate_code: null,
//             parking_instruction: null,
//             is_active: false,
//             pic_1: null,
//             pic_2: null,
//             pic_3: null,
//             pic_4: null,
//             BD_pic_1: null,
//             BD_pic_2: null,
//             BD_pic_3: null,
//             BD_pic_4: null,
//             loc_lat: null,
//             loc_lon: null,
//             parking_spot_address1: null,
//             parking_spot_address2: null,
//             country: null,
//             city: null,
//             state: null,
//             zip_code: null,
//             extension_report: null,
//             distance: null,
//             amenities: null,
//             message: null,
//             bookmarked_by:null,
//             liked_by:null
//         }

//         this.map=null
//         this.position= {
//             lat: 0.0,
//             lng: 0.0,
//         }
//         this.address=''
//         this.submitted = false

//     }
    


//     DATA_PROCESSOR_SPOT = (data, setState, ext={}, sp_msg={}) => {
//     let temp = [];

//     for(const spot in data){
//         var obj = {}
//         for(const key in this.DATAFORMATSPOTS){
//             if(key in data[spot]){
//                 obj = {...obj, [key]: data[spot][key]}
//             }
//             else{
//                 obj = {...obj, [key]: null}
//             }
//         }
// 		console.log("------",Object.keys(ext))
// 		if(Object.keys(ext).includes(String(obj.id))){
// 			console.log("------22",ext)
// 			obj.extension_report = ext[String(obj.id)]
// 		}
// 		if(Object.keys(sp_msg).includes(String(obj.id))){
// 			console.log("------22",sp_msg)
// 			obj.message = sp_msg[String(obj.id)]
// 		}
//         temp.push(obj)

//     }
//     setState(temp)
// }


//     handleChange = (event) => {
//         let name = event.target.name;
//         setState({ [event.target.name]: event.target.value }, () => {
//             this.valInput(name)
//         })
//     }
    

//     valInput = (type) => {
//         const val = this.validator.inputValidator(type, this.state);
//         this.setState({ validation: val });
//     }

//     handleSubmit = () => {
//         const validation = this.validator.validate(this.state);
//         this.setState({ validation });
//         if (validation.isValid) {
//             this.setState({ loading: true });

//             const { first_name, last_name, email, message } = this.state

//             var data = new FormData();
//             data.set("first_name", first_name);
//             data.set("last_name", last_name);
//             data.set("email", email);
//             data.set("mesg", message)
//             axiosInstance.post("/provider_dash/contect_us_pro", data)
//                 .then(() => {
//                     this.setState({ loading: false })
//                     this.setState({ apiStatus: true }, () => {
//                     })

//                 })
//                 .catch(() => {
//                     this.setState({ loading: false })
//                     this.setState({ apiStatus: false }, () => {
//                     })

//                 })
//         }
//     }
//     render() {
//         let validation = this.submitted ?                         // if the form has been submitted at least once
//             this.validator.validate(this.state) :   // then check validity every time we render
//             this.state.validation
//         return (
//                 {this.state.loading ? <LottieGIF json={loader} loop={false} height="8rem" width="8rem"></LottieGIF> : null}

//                 <Helmet>
//                     <meta charSet="utf-8" />
//                     <title>Parking Bud About Us</title>
//                     <link rel="canonical" href="http://acropolisinfotech.com/" />
//                     <meta name="description" content='parking bud provider panel ' />
//                 </Helmet>
//                 <div className='container header d-flex flex-wrap'>
//                     <div className='col-md-6 d-flex flex-column justify-content-center'>
//                         <h3 className='text-blue'>Your parking best friend</h3>

//                         <p className=' text-secondary'>
//                             Never drive around for a parking spot again. Parking Bud makes it simple to
//                             find, book and pay for parking. Reserve your spots in advance and worry less.</p>
//                         <p className='text-secondary'>Let's find your perfect parking spot.</p>
//                         <p className='position-relative mb-0'>
//                             <input type="text" className='form-control mb-3 rounded-10' placeholder='Search by location or place' />
//                             <i className="bi bi-search search-button"></i>
//                             <SearchMapSection
//                                 map={this.map}
//                                 setState={this.setState}
//                                 state={this.state}
//                                 setPosition={this.setPosition}
//                             />
//                         </p>
//                         <p className=' d-flex justify-content-center'>
//                             <img className='app-store-image' src={Android} alt="Android" />
//                             <img className='app-store-image' src={IOS} alt="IOS" />
//                         </p>
//                     </div>
//                     <div className='col-md-6 text-right'>
//                         <img className='mobile-image' src={Mobile} alt="Parking bud" />
//                     </div>
//                 </div>
//                 <div className='row light-blue-gradient'>
//                     <div className='container p-4'>
//                         <h3 className='text-blue mb-4 mt-2'>How It Works</h3>
//                         <div className='align-items-start' style={{ transform: 'translate3d(-340px, 0px, 0px)' }}>
//                             <LottieGIF json={How} loop={false} height="25rem" width="110rem" ></LottieGIF></div>
//                         <div className='row text-center mt-2'>
//                             <div className='col-md-4'>
//                                 <p className='text-blue'>Find a Place</p>
//                                 <p className=''>Lorep ipsum simply dummy text</p>
//                                 <p className=''>Lorep ipsum simply dummy text</p>
//                             </div>
//                             <div className='col-md-4'>
//                                 <p className='text-blue'>Reserve It</p>
//                                 <p className=''>It's fast and easy to book and pay for parking!</p>
//                                 <p className=''>Quit worrying about losing your parking spot
//                                     Book it in just a click and the work is over</p>
//                             </div>
//                             <div className='col-md-4'>
//                                 <p className='text-blue'>Park Vehicle</p>
//                                 <p className=''>Find your perfect spot waiting for you.</p>
//                                 <p className=''>The app help you navigate to your destination park hassle-free.</p>
//                             </div>
//                         </div>

//                     </div>


//                 </div>
//                 <div className='row'>
//                     <div className='container text-center p-4'>
//                         <h3 className='text-blue mt-4'>Booking Options</h3>
//                         <p className='text-secondary'>Lorep ipsum is simply dummy text</p>
//                         <div className='row'>
//                             <div className='col-md-6'>
//                                 <img src={Clock} alt="Clock" />
//                                 <h5 className='text-blue'>Hourly</h5>
//                                 <p className='text-secondary'>
//                                     Hourly is the perfect option for those on the
//                                     move - enter and exit at specified hours for
//                                     cheaper booking rates.
//                                 </p>

//                             </div>
//                             <div className='col-md-6'>
//                                 <img src={Calendar} alt="Calendar" />
//                                 <h5 className='text-blue'>Monthly</h5>
//                                 <p className='text-secondary'>Renting some space, or working an intermship?
//                                     This one Is perfect fyou need 1o ensure.
//                                     space for your ca for an entre month.</p>

//                             </div>
//                         </div>
//                     </div>

//                 </div>
                





            
                
//         )
//     }
// }


// export default HowItWorks


// class Testimonials extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {}
//     }
//     render() {
//         return (
//             <div id="demo" className="carousel slide" data-ride="carousel">
//                 <ul className="carousel-indicators">
//                     <li data-target="#demo" data-slide-to="0" className="active"></li>
//                     <li data-target="#demo" data-slide-to="1"></li>
//                     <li data-target="#demo" data-slide-to="2"></li>
//                 </ul>
//                 <div className="carousel-inner text-center p-4">
//                     <div className="carousel-item p-4 active">
//                         <img className='left-quote' src={LeftQuote} alt="Left Quote" />
//                         <p className='text-secondary h5'>
//                             Using Parking bud was awesome.l completely forgot how much of a hassle parking gets and was in a 3
//                             rush...Using ParkWhiz, | found a spot and was there within seconds!
//                         </p>
//                         <h5 className='text-blue'>Ashley C.</h5>
//                         <img className='right-quote' src={RightQuote} alt="Right Quote" />
//                     </div>
//                     <div className="carousel-item p-4">
//                         <img className='left-quote' src={LeftQuote} alt="Left Quote" />
//                         <p className='text-secondary h5'>Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text</p>
//                         <h5 className='text-blue'>Author</h5>
//                         <img className='right-quote' src={RightQuote} alt="Right Quote" />
//                     </div>
//                     <div className="carousel-item p-4">
//                         <img className='left-quote' src={LeftQuote} alt="Left Quote" />
//                         <p className='text-secondary h5'>Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text
//                             Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text.Lorep ipsum is simply dummy text</p>
//                         <h5 className='text-blue'>Author</h5>
//                         <img className='right-quote' src={RightQuote} alt="Right Quote" />
//                     </div>
//                 </div>

//             </div>
//         )
//     }
// }