import PublicRoute from './routes/public/PublicRoutes'
import './App.css'
import ForgotPass from './store/public/forgotPassword/forgotPass';
import BusinessInfo from './store/public/BusinessInfo/BusinessInfo';
import BusinessInfo2 from './store/public/BusinessInfo2/BusinessInfo';
import BusinessInfo3 from './store/public/BusinessSignup/BusinessInfo';

function App() {

  return (
    <ForgotPass>
      <BusinessInfo>
      <BusinessInfo2>
      <BusinessInfo3>
      <PublicRoute/>
      </BusinessInfo3>
      </BusinessInfo2>
      </BusinessInfo>
    </ForgotPass>
  );
}

export default App;
