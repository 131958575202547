import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// icons
import Dot from "../../../../assets/Icons/dot2x.png";

import WheelGradiant from "../../../../assets/Icons/wheelGradiant.png";
import DirectionIcon from "../../../../assets/Icons/directions.png";
import BusGradiant from "../../../../assets/Icons/busGradiant.png";
import HomeParkingGrad from "../../../../assets/Icons/home-parkingGrad.png";
import SelfParkGradIcon from "../../../../assets/Icons/self-parkGrad.png";
import ShieldGradIcon from "../../../../assets/Icons/shield-grad.png";
import AttendenGradIcon from "../../../../assets/Icons/attendant-grad.png";
import { CONVERTHOURSOFOPERATION } from "../../../../components/Tools";

const ParkingSpotDetailsMap = ({ className, setStep, parking, handleBookmark }) => {
	const history = useHistory();

	const handleCardClick = useCallback(
		(e) => {
			setStep("list");
		},
		[setStep]
	);

	const handleClick = useCallback(() => {
		window. location. replace('https://stg-uf.parkingbud.com')
		// history.push("https://stg-uf.parkingbud.com/home/reservation/Tribeca Parking/asud57f34523");
	}, [history]);

	return (
		<div className={className}>
			<div className='wrapper'>
				<button className='view-all' onClick={handleCardClick}>
					<span>ViewAll</span>
					<i className='bi bi-sliders'></i>
				</button>

				<div className='info'>
					<img
						className='preview'
						src={parking? parking.pic_1? 
							parking.pic_1:
							'https://images.pexels.com/photos/8562238/pexels-photo-8562238.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
							:
							'https://images.pexels.com/photos/8562238/pexels-photo-8562238.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
						}
						alt='parking-spot'
					/>

					<div className='small__preview'>
						<img
							src={parking? parking.pic_2? 
							parking.pic_2:
							'https://images.pexels.com/photos/8562238/pexels-photo-8562238.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
							:
							'https://images.pexels.com/photos/8562238/pexels-photo-8562238.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
						
						}
							alt='parking-spot'
						/>
						<img
							src={parking? parking.pic_3? 
							parking.pic_3:
							'https://images.pexels.com/photos/8562238/pexels-photo-8562238.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
							:
							'https://images.pexels.com/photos/8562238/pexels-photo-8562238.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
						}
							alt='parking-spot'
						/>
						<img
							src={parking? parking.pic_4? 
							parking.pic_4:
							'https://images.pexels.com/photos/8562238/pexels-photo-8562238.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
							:
							'https://images.pexels.com/photos/8562238/pexels-photo-8562238.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
						
						}
							alt='parking-spot'
						/>
						<img
							src={'https://images.pexels.com/photos/8562238/pexels-photo-8562238.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'}
							alt='parking-spot'
						/>
					</div>

					<div className='heading'>
						<h6>{parking? parking.parking_name: null}</h6>
						<i className='bi bi-bookmark-fill bookmark' style={{cursor: 'pointer'}} onClick={()=>{
							handleBookmark(parking? parking.id: null)}}></i>
					</div>

					<p className='description'>
						{parking? parking.parking_brief_discription: null}
					</p>

					<div className='start__info'>
						<p>
							<i className='bi bi-star-fill'></i>
							{parking? parking.total_ratings: null}
						</p>
						<img src={Dot} alt='dot' className='divider' />
						<p>
							<i className='bi bi-suit-heart-fill'></i>
							{parking? parking.likes: null}
						</p>
						<img src={Dot} alt='dot' className='divider' />
						<p>
							<i className='bi bi-geo-alt-fill'></i>
							{parking? parking.distance: null}
						</p>
					</div>

					<div className='price__tag'>
						<span>${parking? parking.hourly_rate: null}</span>
					</div>

					<div className='directions'>
						<div className='address'>
							<i className='bi bi-geo-alt-fill'></i>
							<p>
								{parking &&
								<>
								<span>
								{parking.parking_spot_address1 != ""?parking.parking_spot_address1+", ": null}
								{parking.parking_spot_address2 != ""?parking.parking_spot_address2+", ": null}
								{parking.city != ""?parking.city+", ": null}

								</span>
								
								<span>
								{parking.state != ""?parking.state+", ": null}
								{parking.country != ""?parking.country: null}
								</span>
								</>
								}
							</p>
						</div>

						<div className='location' onClick={() => setStep("directions")}>
							<img src={DirectionIcon} alt='DirectionIcon' />
							<span>Direction</span>
						</div>
					</div>

					<div className='button__group'>
						<button className='appButton' onClick={handleClick}>
							Book Now
						</button>
					</div>

					<div className='specs'>
						<h4>Amenities</h4>

						<div className='specs__info'>
							<div className='row'>
							{parking && parking.amenities && parking.amenities.map((item, i) => {
								return (
								<div className="col-md-6 d-flex flex-row mb-3" key={i}>
									
									{item.icon_img && <img src={item.icon_img}  alt='icon' />}
									<p>{item.name}</p>
									
								</div>)
								})
							}
							</div>
							{/*
							<div className='left__info'>
								<div>
									<img src={WheelGradiant} alt='WheelGradiant' />
									<p>ADA Parking</p>
								</div>

								<div>
									<img src={BusGradiant} alt='BusGradiant' />
									<p>Oversized Vehicle</p>
								</div>

								<div>
									<img src={HomeParkingGrad} alt='HomeParkingGrad' />
									<p>Covered</p>
								</div>
							</div>
							<div className='right__info'>
								<div>
									<img src={SelfParkGradIcon} alt='SelfParkGradIcon' />
									<p>Self park</p>
								</div>

								<div>
									<img src={ShieldGradIcon} alt='ShieldGradIcon' />
									<p>Secure Location</p>
								</div>

								<div>
									<img src={AttendenGradIcon} alt='AttendenGradIcon' />
									<p>Attendant</p>
								</div>
							</div>
							*/}
						</div>
					</div>

					<div className='specs'>
						<h4>Instructions</h4>

						<div className='instructions'>
						{parking && parking.parking_instruction != "[]" && 
                        parking.parking_instruction.slice(1,-1).split(',').map((value) => {
							return (
							
						<p>{value}</p>
							
						 	)
						})
						}
						</div>
					</div>

					<div className='specs'>
						<h4>Hours of Operation</h4>

						<div className='instructions'>
							{parking && parking.sun_hourly &&
							(
								<p>Sun {CONVERTHOURSOFOPERATION(parking.sun_hourly)}</p>
								
							)
							}
							{parking && parking.mon_hourly &&
							(
								<p>Mon {CONVERTHOURSOFOPERATION(parking.mon_hourly)}</p>
								
							)
							}
							{parking && parking.tus_hourly &&
							(
								<p>Tue {CONVERTHOURSOFOPERATION(parking.tus_hourly)}</p>
								
							)
							}
							{parking && parking.wed_hourly &&
							(
								<p>Wed {CONVERTHOURSOFOPERATION(parking.wed_hourly)}</p>
								
							)
							}
							{parking && parking.thu_hourly &&
							(
								<p>Thur {CONVERTHOURSOFOPERATION(parking.thu_hourly)}</p>
								
							)
							}
							{parking && parking.fri_hourly &&
							(
								<p>Fri {CONVERTHOURSOFOPERATION(parking.fri_hourly)}</p>
								
							)
							}
							{parking && parking.sat_hourly &&
							(
								<p>Sat {CONVERTHOURSOFOPERATION(parking.sat_hourly)}</p>
								
							)
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ParkingSpotDetailsMap.propTypes = {
	className: PropTypes.string.isRequired,
	setStep: PropTypes.func.isRequired,
};

export default memo(ParkingSpotDetailsMap);
