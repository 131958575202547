import React from 'react';
import LottieGIF from '../components/lottieComponent';
import loader from '../assets/JSON-Gifs/GIF/25.loading.json';

const _ = (classes) => {
    let s = "";
    classes.map((i) => (s += i + " "));
    return s;
  };
function DefaultLoader(props) {
    return (
        <div
        className={_([
          "d-flex flex-column align-items-center justify-content-center",
        ])}
        style={{ height: "90vh" }}
      >
        <LottieGIF
          json={loader}
          loop={false}
          height="8rem"
          width="8rem"
        ></LottieGIF>
      </div>
    )
}


export default DefaultLoader