import React from 'react';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import axiosInstance from '../../../axios';
import queryString from 'query-string';
import './about-us.css'
import LottieGIF from '../../../components/lottieComponent';
import loader from "../../../assets/JSON-Gifs/GIF/25.loading.json"

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search:queryString.parse(this.props.location.search),
            content: '',
            whoWeAre: queryString.parse(this.props.location.search).search ? false : true,
            ourVision: false,
            helpFAQ: queryString.parse(this.props.location.search).search === 'faq' ? true : false,
            privacy:queryString.parse(this.props.location.search).search === 'privacy' ? true : false,
            whoWeAreContent: '',
            ourVisionContent: '',
            faqData: '',
            loading: false
        }
    }

    handleTab = (event) => {
        let element = event.target;
        let name = element.getAttribute("name");
        this.setState({ whoWeAre: false });
        this.setState({ ourVision: false });
        this.setState({ helpFAQ: false });
        this.setState({privacy:false})
        var { ourVisionContent, whoWeAreContent, pirvacyContent } = this.state

        if (name === "ourVision") {

            this.setState({ ourVision: true })
            this.setState({ content: ourVisionContent })
        }
        if(name === "privacy")
        {
            this.setState({privacy:true});
            this.setState({content:pirvacyContent})
        }
        if (name === "whoWeAre") {
            this.setState({ whoWeAre: true })
            this.setState({ content: whoWeAreContent })

        }
        if (name === "helpFAQ") {
            this.setState({ helpFAQ: true })
            this.setState({ content: "Help and FAQ comes here" })

        }
    }

    getPlainText = (newHTML) => {
        var html = "<p>Some HTML</p>";
        var div = document.createElement("div");
        div.innerHTML = newHTML;
        var text = div.textContent || div.innerText || "";
        return text
    }

    whoWeAreApi = () => {
        this.setState({ loading: true })

        axiosInstance.get(
            "/provider_dash/cms_about_us-who_we_are",
        )
            .then((res) => {
                let data = res.data.response.data.content;
                let text = this.getPlainText(data);
                this.setState({ whoWeAreContent: text }, () => {
                    this.setState({ loading: false })
                });
                this.setState({ content: this.state.whoWeAreContent });

            })
            .catch(() => {

            })
    }

    privacyApi = () => {
        this.setState({ loading: true })

        axiosInstance.get(
            "/provider_dash/cms_about_us-privacy_policy",
        )
            .then((res) => {
                let data = res.data.response.data.content;
                let text = this.getPlainText(data);
                this.setState({ pirvacyContent: text }, () => {
                    this.setState({ loading: false })
                });

            })
            .catch(() => {

            })
    }

    faqAPI = () => {
        this.setState({ loading: true });
        var data = new FormData();
        data.set("categories", "Provider");
        data.set("page", "1");
        data.set("result_limit", 20);

        axiosInstance.post(
            "/provider_dash/view_all_faqs",
            data
        )
            .then((res) => {
                let data = res.data.response.result;
                console.log(data);
                this.setState({ faqData: data }, () => {
                    this.setState({ loading: false })
                });

            })
            .catch(() => {

            })

    }

    visionAPI = () => {
        this.setState({ loading: true });

        axiosInstance.get(
            "/provider_dash/cms_about_us-our_vision",
        )
            .then((res) => {
                let data = res.data.response.data.content;
                let text = this.getPlainText(data);
                this.setState({ ourVisionContent: text }, () => {
                    this.setState({ loading: false })
                });

            })
            .catch(() => {

            })

    }

    componentDidMount() {
        this.faqAPI();
        this.whoWeAreApi();
        this.visionAPI();
        this.privacyApi();
    }

    render() {
        return (
            <div className='container p-4'>
                <div className='row m-0'>
                    <div className='col-md-12'>
                        <h3 className='text-blue mb-4'>About Us</h3>
                    </div>
                </div>
                <div className='row m-0'>
                    <div className='col-md-3'>
                        <div className='row m-0'>
                            <Tab
                                dataName="whoWeAre"
                                onClick={this.handleTab} name="Who We Are" active={this.state.whoWeAre} />
                            <Tab
                                dataName="ourVision"
                                onClick={this.handleTab} name="Our Vision" active={this.state.ourVision} />
                            <Tab
                                dataName="helpFAQ"
                                onClick={this.handleTab} name="Help and FAQ" active={this.state.helpFAQ} />
                            <Tab
                                dataName="privacy"
                                onClick={this.handleTab} name="Privacy Policy" active={this.state.privacy} />

                            <div className='col-md-12'>
                                <Link className='tab-link' to="/terms-conditions">
                                    <p className="tab-button" style={{ fontWeight: '600' }}>Terms</p>
                                </Link>
                            </div>

                        </div>

                    </div>
                    <div className='col-md-8 offset-md-1'>
                        <div className='row m-0'>
                            <div className='col-md-12 shadow-box'>
                            {this.state.loading?<LottieGIF json={loader} loop={false} height="8rem" width="8rem"></LottieGIF>:null}
                                {
                                    this.state.helpFAQ === true && this.state.faqData && this.state.faqData.length > 0 ?
                                        this.state.faqData.map((item, index) => (
                                            <FAQ key={item.id} question={item.question} answer={item.answer} />
                                        ))
                                        : <p>{this.state.content}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUs


class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className='col-md-12'>
                <p name={this.props.dataName} onClick={this.props.onClick} className={`pb-2 tab-button ${this.props.active === true ? 'active' : ''}`}>{this.props.name}</p>
            </div>
        )
    }
}

class FAQ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className='col-md-12'>
                <div className='row m-0'>
                    <div className='col-md-12'>
                        <p className='text-info mb-0'>{this.props.question}</p>
                    </div>
                    <div className='col-md-12'>
                        <p className=''>{this.props.answer}</p>
                    </div>
                </div>
            </div>
        )

    }
}